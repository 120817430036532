import { defineMessages } from 'react-intl';

export default defineMessages({
    newPassword: {
        id: 'auth.components.ResetPassword.New Password',
        defaultMessage: 'New Password'
    },
    fieldEmpty: {
        id: 'auth.components.ResetPassword.Enter your new password.',
        defaultMessage: 'Enter your new password.'
    },
    fieldIncorrect: {
        id: 'auth.components.ResetPassword.Fields password and confirmation do not match.',
        defaultMessage: 'Fields password and confirmation do not match.'
    },
    password: {
        id: 'auth.components.ResetPassword.New Password:',
        defaultMessage: 'New Password:'
    },
    confirmPassword: {
        id: 'auth.components.ResetPassword.Confirm Password:',
        defaultMessage: 'Confirm Password:'
    },
    backToSignIn: {
        id: 'auth.components.ResetPassword.Back to Sign In',
        defaultMessage: 'Back to Sign In'
    },
    changePassword: {
        id: 'auth.components.ResetPassword.Change Password',
        defaultMessage: 'Change Password'
    },
    passwordSuccessfullyChanged: {
        id: 'auth.components.ResetPassword.Password successfully changed',
        defaultMessage: 'Password successfully changed'
    },
    returnToSignInAndEnterNewPassword: {
        id: 'auth.components.ResetPassword.Return to Sign In and enter your new password.',
        defaultMessage: 'Return to Sign In and enter your new password.'
    },
    invalidToken: {
        id: 'auth.components.ResetPassword.Invalid token',
        defaultMessage: 'Invalid token'
    },
    resetPasswordLinkDoesntWork: {
        id: 'auth.components.ResetPassword.Sorry, your reset password link is no longer valid.',
        defaultMessage: 'Sorry, your reset password link is no longer valid.'
    },
    unableToProcessYourRequest: {
        id: 'auth.components.ResetPassword.Unable to process your request',
        defaultMessage: 'Unable to process your request'
    },
    resetPasswordLinkNotWorkingOrOldPassword: {
        id: 'auth.components.ResetPassword.Reset password link is no longer valid or your new password should not be same as old password.',
        defaultMessage: 'Reset password link is no longer valid or your new password should not be same as old password.'
    },
    invalidAttempt: {
        id: 'auth.components.ResetPassword.Invalid attempt',
        defaultMessage: 'Invalid attempt'
    },
    unableToProcessYourRequestError: {
        id: 'auth.components.ResetPassword.Unable to process your request.',
        defaultMessage: 'Unable to process your request.'
    }
});
