import React from 'react';
import createHOC from 'react-create-hoc';

import useDependencies from './useDependencies';

/**
 * @typedef {Function} WrappingFunction
 * @param Component the component to wrap
 */

/**
 * HOC component that makes passes the required dependencies as props
 * This must be used as a factory providing the dependency names before wrapping the component
 *
 * @param {String|Array<String>} dependencies the dependencies that should be
 *                                              injected into the wrapped component
 * @returns {WrappingFunction} a functoin to wrap the given component with dependencies
 *
 * @example
 *
 * ```
 * withDependencies(['dependencyName1', `dependencyName2'])(MyCopmonent)
 * ```
 */
const withDependencies = createHOC(
    'withDependencies',
    (ComponentToWrap, dependencies) => {
        function WithDependenciesComponent(props) {
            const resolvedDependencies = useDependencies(dependencies);
            return <ComponentToWrap {...props} {...resolvedDependencies} />;
        }

        return WithDependenciesComponent;
    },
    { factory: true }
);

export default withDependencies;
