import React from 'react';
import { ServiceManager } from '@jutro/services';
import { CurrencyField } from '@jutro/components';

function Currency(props) {
    const localeService = ServiceManager.getService('locale-service');
    return (
        <CurrencyField
            {...props}
            defaultCurrency={localeService.getDefaultCurrencyCode()}
        />
    );
}

Currency.propTypes = CurrencyField.propTypes;
Currency.defaultProps = CurrencyField.defaultProps;

export default Currency;
