import React from 'react';
import { ServiceManager } from '@jutro/services';
import { PhoneNumberField } from '@jutro/components';

function PhoneNumber(props) {
    const localeService = ServiceManager.getService('locale-service');
    return (
        <PhoneNumberField
            {...props}
            countryCode={localeService.getDefaultPhoneCountry()}
        />
    );
}

PhoneNumber.propTypes = PhoneNumberField.propTypes;
PhoneNumber.defaultProps = PhoneNumberField.defaultProps;

export default PhoneNumber;
