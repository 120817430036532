import { defineMessages } from 'react-intl';

export default defineMessages({
    signUp: {
        id: 'auth.components.LockLogin.Sign up',
        defaultMessage: 'Sign up'
    },
    doYouHavePolicy: {
        id: 'auth.components.LockLogin.Do you have a policy with us but don’t have an account?',
        defaultMessage: 'Do you have a policy with us but don’t have an account?'
    }
});
