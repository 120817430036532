import React, {
    useCallback,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { BreakpointTracker } from '@jutro/layout';

export default function AccurateBreakpointPropagation({ children }) {
    const handleResize = useCallback((evt) => {
        setTimeout(
            () => {
                // window.innerWidth is read-only according to the spec
                // but it seems to be generally writeable.
                // we wrap this in a try-catch in case of exceptions raised
                try {
                    // because of https://bugs.webkit.org/show_bug.cgi?id=170595
                    // we cannot rely on innerWidth on webkit based browsers
                    // therefore we forcefully assign to the document.clientWidth
                    // so that we can correctly use the BreakpointTrackers across browsers
                    window.innerWidth = document.documentElement.clientWidth;
                    window.dispatchEvent(new Event(evt.type));
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            },
            70 // this allows the initial render to complete before firing a new event
        );
    });

    useEffect(() => {
        window.addEventListener('onresize', handleResize);
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('onresize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, [handleResize]);
    return (
        <BreakpointTracker>{children}</BreakpointTracker>
    );
}

AccurateBreakpointPropagation.propTypes = {
    children: PropTypes.node.isRequired
};
