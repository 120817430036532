/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { AuthenticationServiceFactory } from 'gw-portals-auth-js';
// importing LocaleService
import { LocaleService } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
// importing UtilService
import UtilService from '../../services/UtilService';
import { useHistory } from 'react-router-dom';

let inactivityTimerId;
let logoutConfirmationTimerId;

export default ({
    oAuthConfig,
    logoutWarningCallback,
    inactivityIntervalMins = 5,
    logoutConfirmationIntervalMins = 1
}) => {
    const authenticationService = AuthenticationServiceFactory(oAuthConfig);
    const auth = useAuthentication();
    const history = useHistory();

    function initializeInActivityTimer() {
        const inactivityInterval = inactivityIntervalMins * 1000 * 60;
        // initial timer to check for inactivity
        inactivityTimerId = window.setTimeout(
            () => {
                const logoutConfirmationInterval = logoutConfirmationIntervalMins * 1000 * 60;

                // second timer to show warning message for a period of time
                logoutConfirmationTimerId = window.setTimeout(() => {
                    // applying google analytics tag
                    auth.logout().then(() => {
                        UtilService.deleteAllCookies();
                        sessionStorage.clear();
                        history.push('/session-expired');
                    });
                }, logoutConfirmationInterval);
                // eslint-disable-next-line no-use-before-define
                logoutWarningCallback(resetInactivityTimer, logoutConfirmationInterval);
            },
            inactivityInterval
        );
    }

    function deactivateTimers() {
        if (inactivityTimerId) {
            window.clearTimeout(inactivityTimerId);
            inactivityTimerId = null;
        }
        if (logoutConfirmationTimerId) {
            window.clearTimeout(logoutConfirmationTimerId);
            logoutConfirmationTimerId = null;
        }
    }

    function resetInactivityTimer() {
        deactivateTimers();
        initializeInActivityTimer();
    }

    authenticationService.onLoginStateChange((authData) => {
        const authenticatedFlag = authData.isLoggedIn;
        if (authenticatedFlag) {
            // kick off a timer when user logs in
            resetInactivityTimer();
        } else {
            // if user has logged out then deactivate the timers
            deactivateTimers();
        }
    });

    return {
        // this method is called by the transport service,
        // called every time the user makes an XHR call to the backend -
        resetInactivityTimer: resetInactivityTimer
    };
};
