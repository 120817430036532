/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import {
    ModalNextProvider,
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@jutro/components';

function ConfirmationHelper({
    isOpen,
    onResolve,
    onConfirm,
    onCancel,
    title,
    content,
    yesButtonText,
    noButtonText,
}) {
    function handleContinue() {
        sessionStorage.setItem('isCloseClicked', 'false');
        onConfirm();
        onResolve('close'); // return value for dialog close is not used.
    }

    function handleClose() {
        sessionStorage.setItem('isCloseClicked', 'true');
        onCancel();
        onResolve('close'); // return value for dialog close is not used.
    }

    return (
        <ModalNext 
            shouldCloseOnOverlayClick={false} 
            isOpen={isOpen} 
            onRequestClose={() => onResolve('close')}
            ariaLabelledby="popupTitle"
            ariaDescribedby="popupDescription"
        >
            <ModalHeader title={<h2 id='popupTitle' className="diModalHeader">{title}</h2>} />
            <ModalBody>
                <div id='popupDescription' className="diModalContent">{content}</div>
            </ModalBody>
            <ModalFooter>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ marginBottom: '30px' }}>
                        <Button className="normalSpace diButtonWidth" onClick={handleContinue} type="secondary">{yesButtonText}</Button>
                    </span>
                    <span>
                        <Button className="normalSpace diButtonWidth" onClick={handleClose} type="primary">
                            {noButtonText}
                        </Button>
                    </span>
                </div>
            </ModalFooter>
        </ModalNext>
    );
}

async function OpenConfirmationDialog(
    title,
    content,
    yesButtonText,
    noButtonText,
    onConfirm,
    onCancel
) {
    try {
        const results = await ModalNextProvider.showModal(
            <ConfirmationHelper
                onConfirm={onConfirm}
                onCancel={onCancel}
                title={title}
                content={content}
                yesButtonText={yesButtonText}
                noButtonText={noButtonText}
            />
        ).then((result) => {
            console.log(`Dialog closed: ${result}`);
            return result;
        });

        console.log(`User selection was: ${results}`);
    } catch (ex) {
        console.log(`modal error was: ${ex}`); // 'close' rejection case
    }
}

export default OpenConfirmationDialog;
