import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { useAuthentication } from './AuthenticationContext';

function getDestination(from, loginPath) {
    if (from === loginPath) {
        return loginPath;
    }
    return from;
}

function RedirectAfterLogin({ successPath, loginPath, createProfilePath }) {
    // retain previous state
    const [wasLoggedIn, updateWasLoggedIn] = useState(false);
    const { isLoggedIn, name } = useAuthentication();
    useEffect(() => {
        updateWasLoggedIn(isLoggedIn);
    }, [isLoggedIn]);

    console.log(`RedirectAfterLogin username: ${name}`);

    if (isLoggedIn && !wasLoggedIn) {
        // ospcchange
        if ((name === '' || name === undefined) && createProfilePath !== '') {
            console.log(`redirect to create profile page: ${createProfilePath}`);
            // SE-1258 New User Sign up- When new user has signed up, create profile page is not
            // getting displayed and also my profile page is not accessible
            // if username does not exist, then redirect to createProfilePath url
            window.location.href = createProfilePath;
            return null;
        }

        const dest = getDestination(successPath, loginPath);
        return <Redirect to={dest} />;
    }

    return null;
}

RedirectAfterLogin.propTypes = {
    successPath: PropTypes.string,
    loginPath: PropTypes.string,
    createProfilePath: PropTypes.string
};

RedirectAfterLogin.defaultProps = {
    successPath: '/',
    loginPath: '/login-page',
    createProfilePath: ''
};

export default withRouter(RedirectAfterLogin);
