import { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

class TranslatedText extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        children: PropTypes.string.isRequired
    };

    render() {
        const { intl, children } = this.props;

        return intl.formatMessage({ id: children });
    }
}

export default injectIntl(TranslatedText);
