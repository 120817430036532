import { defineMessages } from 'react-intl';

export default defineMessages({
    appName: {
        id: 'portal.name',
        defaultMessage: 'CustomerEngage Account Management'
    },
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    home: {
        id: 'app.html.index.Home',
        defaultMessage: 'Home'
    },
    additionalEnrollment: {
        id: 'portal.index.Additional Enrollment',
        defaultMessage: 'Additional Enrollment'
    },
    makeAPayment: {
        id: 'portal.index.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    billingSummary: {
        id: 'portal.index.Billing',
        defaultMessage: 'Billing'
    },
    claimList: {
        id: 'portal.index.Claims',
        defaultMessage: 'Claims'
    },
    claimDetails: {
        id: 'portal.index.Claim Details',
        defaultMessage: 'Claim Details'
    },
    sessionExpired: {
        id: 'portal.index.sessionExpired',
        defaultMessage: 'session-expired'
    },
    createNote: {
        id: 'portal.index.Create Note',
        defaultMessage: 'Create Note'
    },
    policyChange: {
        id: 'portal.index.Policy Change',
        defaultMessage: 'Policy Change'
    },
    policyDetails: {
        id: 'portal.index.Policy Details',
        defaultMessage: 'Policy Details'
    },
    accountInformation: {
        id: 'portal.index.Account Information',
        defaultMessage: 'Account Information'
    },
    homePage: {
        id: 'portal.index.Home Page',
        defaultMessage: 'Home Page'
    },
    error: {
        id: 'portal.index.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'portal.index.Sorry, an error occurred. You can try later or contact one of our agents.',
        defaultMessage: 'Sorry, an error occurred. You can try later or contact one of our agents.'
    },
    backButton: {
        id: 'portal.index.Back',
        defaultMessage: 'Back'
    },
    NA: {
        id: 'NA',
        defaultMessage: 'N/A'
    },
    MSG03: {
        id: 'osbc.messages.MSG03',
        defaultMessage: 'No data to display'
    },
    MSG02: {
        id: 'osbc.messages.MSG02',
        defaultMessage: 'No claims available'
    },
    MSG04: {
        id: 'osbc.messages.MSG04',
        defaultMessage: 'We’re experiencing a technical issue. Please try again later.'
    },
    MSG37: {
        id: 'osbc.messages.MSG37',
        defaultMessage: 'No claims found.'
    },
    MSG38: {
        id: 'osbc.messages.MSG38',
        defaultMessage: 'Please enter search criteria.'
    },
    MSG39: {
        id: 'osbc.messages.MSG39',
        defaultMessage: 'The display is already in default view.'
    },
    MSG68: {
        id: 'osbc.messages.MSG68',
        defaultMessage: 'The display is already in default view.'
    },
    MSG01: {
        id: 'osbc.messages.MSG01',
        defaultMessage: 'The display is already in default view.'
    },
    MSG17: {
        id: 'osbc.messages.MSG17',
        defaultMessage: 'The display is already in default view.'
    },
    MSG24: {
        id: 'osbc.messages.MSG24',
        defaultMessage: 'The display is already in default view.'
    },
    MSG59: {
        id: 'osbc.messages.MSG59',
        defaultMessage: 'The display is already in default view.'
    },
    MSG60: {
        id: 'osbc.messages.MSG60',
        defaultMessage: 'The display is already in default view.'
    },
    MSG61: {
        id: 'osbc.messages.MSG61',
        defaultMessage: 'The display is already in default view.'
    },
    MSG62: {
        id: 'osbc.messages.MSG62',
        defaultMessage: 'The display is already in default view.'
    },
    MSG33: {
        id: 'osbc.messages.MSG33',
        defaultMessage: 'There is no information to display.'
    },
    MSG75: {
        id: 'osbc.messages.MSG75',
        defaultMessage: 'The display is already in default view.'
    },
    MSG77: {
        id: 'osbc.messages.MSG77',
        defaultMessage: 'You no longer have access to this account(s).'
    },
    MSG82: {
        id: 'osbc.messages.MSG82',
        defaultMessage: 'The display is already in default view.'
    },
    MSG86: {
        id: 'osbc.messages.MSG86',
        defaultMessage: 'The display is already in default view.'
    },
    MSG89: {
        id: 'osbc.messages.MSG89',
        defaultMessage: 'Please enter the full eight-digit claim number'
    },
    MSG88: {
        id: 'osbc.messages.MSG88',
        defaultMessage: 'Date of injury format is DD/MM/YYYY'
    },
    MSG87: {
        id: 'osbc.messages.MSG87',
        defaultMessage: 'Please add more search criteria in your search or select exact match '
    },
    MSG74: {
        id: 'osbc.messages.MSG74',
        defaultMessage: 'The display is already in default view.'
    },
    claimsPageTitle: {
        id: 'osbc.aoda.claims',
        defaultMessage: 'My claims | WSIB Online services'
    },
    addClaimPageTitle: {
        id: 'osbc.aoda.addClaim',
        defaultMessage: 'Add a claim | WSIB Online services'
    },
    claimConfirmationPageTitle: {
        id: 'osbc.aoda.claimConfirmation',
        defaultMessage: 'Add a claim confirmation | WSIB Online services'
    },
    claimOverviewPageTitle: {
        id: 'osbc.aoda.claimOverview',
        defaultMessage: 'My claim overview | WSIB Online services'
    },
    benefitPaymentPageTitle: {
        id: 'osbc.aoda.benefitPayments',
        defaultMessage: 'Benefit payments | WSIB Online services'
    },
    healthCarePageTitle: {
        id: 'osbc.aoda.healthCare',
        defaultMessage: 'Health care benefits | WSIB Online services'
    },
    nonHealthCarePageTitle: {
        id: 'osbc.aoda.nonHealthCare',
        defaultMessage: 'Non-health care benefits | WSIB Online services'
    },
    appealsPageTitle: {
        id: 'osbc.aoda.appeals',
        defaultMessage: 'Appeals | WSIB Online services'
    },
    claimDocumentsPageTitle: {
        id: 'osbc.aoda.claimDocuments',
        defaultMessage: 'Submit document(s) | WSIB Online services'
    },
    documentHistoryPageTitle: {
        id: 'osbc.aoda.documentHistory',
        defaultMessage: 'Submitted documents | WSIB Online services'
    },
    claimDirectdepositPageTitle: {
        id: 'osbc.aoda.claimDirectdeposit',
        defaultMessage: 'Direct deposit | WSIB Online services'
    },
    sendUsMessagePageTitle: {
        id: 'osbc.aoda.sendUsMessage',
        defaultMessage: 'Send us a message | WSIB Online services'
    },
    mailPageTitle: {
        id: 'osbc.aoda.Mail',
        defaultMessage: 'Mail | WSIB Online services'
    },
    helpFullLinkPageTitle: {
        id: 'osbc.aoda.helpFullLink',
        defaultMessage: 'Helpful links | WSIB Online services'
    },
    faqLinkPageTitle: {
        id: 'osbc.aoda.faqLink',
        defaultMessage: 'Frequently asked questions | WSIB online services'
    },
    defaultPageTitle: {
        id: 'osbc.aoda.default',
        defaultMessage: 'WSIB Online services'
    }
});
