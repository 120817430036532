import React, {
    Fragment
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAuthentication, RedirectAfterLogin } from 'gw-digital-auth-react';
// import loader
import { LocaleService } from '@jutro/locale';
import LoaderComponent from '../common/loader/loader';
import UtilService from '../services/UtilService';

function Login({ location }) {
    const { isAuthInProgress } = useAuthentication();
    const { state } = location;
    if (isAuthInProgress) {
        return <LoaderComponent showLoader={!isAuthInProgress} />;
    }

    const successRedirectPath = _.get(state, 'from.pathname') || '/home';
    const loginPath = '/';
    const createProfilePath = UtilService.createProfileUrl(LocaleService.getCurrentLanguage());
    console.log(`get createprofile path: ${createProfilePath}`);

    return (
        <Fragment>
            <RedirectAfterLogin
                successPath={successRedirectPath}
                loginPath={loginPath}
                createProfilePath={createProfilePath}
            />
        </Fragment>
    );
}

Login.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

Login.defaultProps = {
    location: {}
};


export default Login;
