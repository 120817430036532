import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeout: {
        id: 'logout-warning.Session-expiry-heading',
        defaultMessage: 'Session Expiry'
    },
    warningAboutToExpire: {
        id: 'logout-warning.Warning-message',
        defaultMessage: 'Your session will expire in {0} minutes.'
    },
    extendSession: {
        id: 'logout-warning.Extend-session',
        defaultMessage: 'Extend session'
    },
    leaveSession: {
        id: 'logout-warning.Leave-session',
        defaultMessage: 'Leave session'
    }
});

