/* eslint-disable no-unused-vars */
import { start } from '@jutro/app';

import { LocaleService } from 'gw-portals-i18n-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import 'regenerator-runtime';

import App from './app/App';
import myCustomThemeConfig from './themes/customTheme/config';

const { authentication = {} } = appConfig;

let cloudAuthConfig = {};
if (authentication.authServer === 'okta') {
    cloudAuthConfig = authentication.servers.okta;
}

start(App, {
    rootId: 'root',
    messageLoader: LocaleService.loadMessages,
    coreMessageLoader: LocaleService.loadCoreMessages,
    config: [cloudAuthConfig],
    refreshOnLocaleChange: true,
    routerBasename: '/account-management',
    onInit: () => {
        LocaleService.register();
    },
    // eslint-disable-next-line no-warning-comments
    appDescription: 'Description', // ToDo
    // eslint-disable-next-line no-warning-comments
    modalProvider: 'default', // ToDo
    themeConfig: myCustomThemeConfig
});

// eslint-disable-next-line import/prefer-default-export
export { default as SessionTimeoutService } from './wsib/services/SessionTimeoutService';
