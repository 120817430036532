import { useContext } from 'react';
import _ from 'lodash';
import { DependencyContext } from './DependencyContext';

/**
 * Retrieves the specified dependencies from the DependencyContext
 * @param {String|Array<String>} dependencyNames the name(s) of the dependencies that are needed
 * @returns {Object<String, any>} an object which keys are the required dependency names
 */
const useDependencies = (dependencyNames) => {
    const dependencies = useContext(DependencyContext);
    return _.pick(dependencies, dependencyNames);
};

export default useDependencies;
