import { defineMessages } from 'react-intl';

export default defineMessages({
    safetyCheck: {
        id: 'footer.safetyCheckLink',
        defaultMessage: 'Health and safety statistics',
    },
    healthAndSafetyIndex: {
        id: 'footer.healthAndSafetyIndexLink',
        defaultMessage: 'Health and Safety Index',
    },
    careers: {
        id: 'footer.careersLink',
        defaultMessage: 'Careers',
    },
    termsOfUse: {
        id: 'footer.termsOfUseLink',
        defaultMessage: 'Terms of use',
    },
    contactUs: {
        id: 'footer.contactUsLink',
        defaultMessage: 'Contact us',
    },
    dayOfMourning: {
        id: 'footer.dayOfMourningLink',
        defaultMessage: 'Day of Mourning',
    },
    openData: {
        id: 'footer.openDataLink',
        defaultMessage: 'Open Data',
    },
    accessibility: {
        id: 'footer.accessibilityLink',
        defaultMessage: 'Accessibility',
    },
    privacy: {
        id: 'footer.privacyLink',
        defaultMessage: 'Privacy',
    },
    fairPracticesCommission: {
        id: 'footer.fairPracticesCommissionLink',
        defaultMessage: 'Fair Practices Commission',
    },
    otherLanguages: {
        id: 'footer.otherLanguagesLink',
        defaultMessage: 'Other languages',
    },
    workplaceAccidents: {
        id: 'footer.workplaceAccidentsLink',
        defaultMessage: 'Fatal or catastrophic workplace accidents',
    },
    callUs: {
        id: 'footer.callUsLink',
        defaultMessage: 'Report online or call us at ',
    },
    callUsNumber: {
        id: 'footer.callUsLinkNumber',
        defaultMessage: '1-800-387-0750',
    },
    ContactUsNumber: {
        id: 'footer.ContactUsNumberLink',
        defaultMessage: 'Contact us',
    },
    contactNumber: {
        id: 'footer.contactNumberLink',
        defaultMessage: '1-800-387-0750',
    },
    footerCopyright: {
        id: 'footer.CopyrightLink',
        defaultMessage: '© {0}, Workplace Safety and Insurance Board',
    },
    landAcknowledge: {
        id: 'footer.landAcknowledgeLink',
        defaultMessage: 'Land acknowledgement'
    },
    phoneNumberLinkTitle: {
        id: 'footer.title',
        defaultMessage: 'opens in a new window',
    },
});
