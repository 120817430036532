import { defineMessages } from 'react-intl';

export default defineMessages({
    leaveThisPage: {
        id: 'ineligible-claim.leaveThisPage',
        defaultMessage: 'Leave this page'
    },
    navigateBack: {
        id: 'ineligible-claim.navigateBack',
        defaultMessage: 'You can go back to view all  claims.'
    },
    navigateToViewClaims: {
        id: 'ineligible-claim.navigateToViewClaims',
        defaultMessage: 'Go back to view claims'
    },
    navigateToBusinessHome: {
        id: 'ineligible-claim.navigateToBusinessHome',
        defaultMessage: 'Go back to Business home'
    },
    okay: {
        id: 'ineligible-claim.okay',
        defaultMessage: 'Oka'
    }
});
