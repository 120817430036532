import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { usePromise, PROMISE_STATES } from 'gw-portals-promise-react';

function useValidation(componentName = 'Component') {
    const validationPromise = usePromise();
    const [invalidFields, updateInvalidFields] = useState([]);
    const [itemsPendingValidation, setItemsPendingValidation] = useState([]);
    const [validationInitialized, updateValidationInitialized] = useState(false);
    const [validationCallback, setValidationCallback] = useState(() => _.stubTrue);
    const [initialValidationCallback, setInitialValidationCallback] = useState(() => _.stubTrue);
    const [isDirty, setDirty] = useState(false);

    const isInvalidFieldsEmpty = _.isEmpty(invalidFields);
    const areItemsPendingValidation = !_.isEmpty(itemsPendingValidation);
    const isSpecificValidationValid = validationCallback();

    const isValidationInProgress = !validationInitialized
        || areItemsPendingValidation || isSpecificValidationValid === null;

    const isComponentValid = isValidationInProgress
        ? null
        : isInvalidFieldsEmpty && !areItemsPendingValidation && isSpecificValidationValid;

    const registerComponentForValidation = useCallback((fieldID) => {
        setItemsPendingValidation((previousItems) => _.uniq([...previousItems, fieldID]));
    }, []);

    const unregisterItemToBeValidated = useCallback((fieldID) => {
        setItemsPendingValidation((previousItems) => previousItems.filter((id) => id !== fieldID));
    }, []);

    const registerInvalidField = useCallback((fieldID) => {
        updateInvalidFields((previousInvalidFields) => _.uniq([...previousInvalidFields, fieldID]));
    }, []);

    const unregisterInvalidField = useCallback((fieldIDs) => {
        const fieldIDArray = !_.isArray(fieldIDs) ? [fieldIDs] : fieldIDs;

        updateInvalidFields((previousInvalidFields) => previousInvalidFields.filter(
            (invalidID) => !_.includes(fieldIDArray, invalidID)
        ));
    }, []);

    const registerComponentValidationCallback = useCallback((callback) => {
        setValidationCallback(() => callback);
    }, []);

    const registerInitialComponentValidationCallback = useCallback((callback) => {
        setInitialValidationCallback(() => callback);
    }, []);

    /**
     * Check to see if the current field should be added or removed from the invalid array
     * @param {boolean} isValid - is the current clause valid
     * @param {string} fieldID - the field ID of the current field
     */
    const onValidate = useCallback(
        (isValid, fieldID = componentName) => {
            setDirty(true);
            if (_.isNil(isValid)) {
                registerComponentForValidation(fieldID);
            } else {
                if (isValid) {
                    unregisterInvalidField(fieldID);
                } else {
                    registerInvalidField(fieldID);
                }
                unregisterItemToBeValidated(fieldID);
            }
        },
        [
            componentName,
            registerComponentForValidation,
            registerInvalidField,
            unregisterInvalidField,
            unregisterItemToBeValidated
        ]
    );

    const initialValidation = useCallback(() => {
        return validationPromise.promise;
    }, [validationPromise]);

    useEffect(() => {
        updateValidationInitialized(true);
    }, []);

    const resolvePromise = useCallback(() => {
        if (validationInitialized) {
            const isValidPromise = Promise.resolve(isComponentValid).then((isValid) => {
                return isValid ? initialValidationCallback() : false;
            });
            validationPromise.resolve(isValidPromise);
        }
    }, [initialValidationCallback, isComponentValid, validationInitialized, validationPromise]);

    useEffect(() => {
        if (
            isDirty
            && validationPromise.state === PROMISE_STATES.pending
            && !isValidationInProgress
        ) {
            resolvePromise();
        }
    }, [
        isDirty,
        isValidationInProgress,
        itemsPendingValidation,
        resolvePromise,
        validationPromise
    ]);

    return {
        disregardFieldValidation: unregisterInvalidField,
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation: registerComponentValidationCallback,
        registerInitialComponentValidation: registerInitialComponentValidationCallback,
        isValidationInProgress
    };
}

export default useValidation;
