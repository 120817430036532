import OAuthUtil from './utils/OAuthUtil';
import jwtHelper from './utils/JwtHelper';
import AuthenticationServiceFactory from './AuthenticationServiceFactory';
import { getGrantedAuthorities } from './utils/GrantedAuthoritiesUtil';

// EXPORT
function createOAuthService(oAuth) {
    const oAuthUtil = OAuthUtil(
        {
            ...oAuth,
            onRefreshError: AuthenticationServiceFactory(oAuth).logout
        }
    );
    return {
        refreshAccessToken: () => {
            oAuthUtil.removeTokens();
            return oAuthUtil.requestAccessToken();
        },

        get accessToken() {
            return oAuthUtil.waitTokensSet()
                .then((tokens) => {
                    return tokens.accessToken;
                });
        },

        get accessTokenDecoded() {
            return oAuthUtil.waitTokensSet()
                .then((tokens) => {
                    return jwtHelper.decodeToken(tokens.accessToken);
                });
        },

        get grantedAuthorities() {
            return oAuthUtil.waitTokensSet()
                .then(getGrantedAuthorities).catch(() => {
                    return [];
                });
        }
    };
}

export default createOAuthService;
