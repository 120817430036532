/* eslint-disable import/no-extraneous-dependencies */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import UtilService from '../../services/UtilService';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: UtilService.fetchInsightKey(),
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

const trackException = (error) => {
    const customProperties = {};
    // Logging the error message from Service Call to APM Tool
    if (Object.prototype.hasOwnProperty.call(error, 'status')) {
        customProperties.status = error.status;
    }
    if (Object.prototype.hasOwnProperty.call(error, 'baseError')) {
        customProperties.baseError = error.baseError;
    }
    if (Object.prototype.hasOwnProperty.call(error, 'message')) {
        customProperties.message = error.message;
    }

    appInsights.trackException({ exception: error }, customProperties);
};

appInsights.loadAppInsights();
export { reactPlugin, appInsights, trackException };
