import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { TranslatorContext } from '@jutro/locale';
import ImageComponent from '../ImageComponent/ImageComponent';
import styles from './CarouselComponent.module.scss';

class CarouselComponent extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        imageSet: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.string,
                name: PropTypes.string,
                text: PropTypes.shape({
                    header: PropTypes.string,
                    description: PropTypes.string
                })
            })
        ),
        className: PropTypes.string
    };

    static defaultProps = {
        imageSet: [],
        className: undefined
    };

    render() {
        const translator = this.context;
        const { imageSet, className } = this.props;

        return (
            <Carousel
                autoPlay
                showStatus={false}
                showThumbs={false}
                className={classNames(styles.carouselContainer, className)}
            >
                {imageSet.map((imageOject) => (
                    <div key={imageOject.name}>
                        <ImageComponent
                            src={`${imageOject.src}`}
                            alt={translator(imageOject.name)}
                        />
                        {imageOject.text ? (
                            <div className={styles.carouselTextContainer}>
                                <h2 className={styles.header}>
                                    {translator(imageOject.text.header)}
                                </h2>
                                <p className={styles.description}>
                                    {translator(imageOject.text.description)}
                                </p>
                            </div>
                        ) : null}
                    </div>
                ))}
            </Carousel>
        );
    }
}

export default CarouselComponent;
