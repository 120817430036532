import { validateMetadata, renderContentFromMetadata } from '@jutro/uiconfig';
import React, { useEffect, useContext, useCallback } from 'react';
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { Link } from '@jutro/components';
import metadata from './footer.metadata.json5';
import styles from './footer.module.scss';
import message from './footer.messages';
import UtilService from '../../services/UtilService';


export default function Footer() {
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        validateMetadata(metadata);
    }, []);

    useEffect(() => {
        document.getElementById('contactNumberLabel')?.setAttribute('href', 'tel:+1-800-387-0750');
        document.getElementById('callUsNumberLink')?.setAttribute('href', 'tel:+1-800-387-0750');
    });
    /*
     * @param {string} key
     * @returns Link Component
     * To get the Link and message based on key and active language
     */
    const getLink = useCallback(
        (key) => {
            const href = UtilService.footerLink(key, LocaleService.getCurrentLanguage());
            // Accessibility DI-33 remove the aria-label attribute and adding title
            // const ariaLabel = UtilService.fetchAriaLabel(key, LocaleService.getCurrentLanguage());
            return (
                <Link target="_blank" href={href} title="opens in a new tab" className={styles.linkContainer}>
                    {translator(message[key])}
                </Link>
            );
        },
        [translator]
    );

    /*
     * @param {string} key
     * @returns href
     * To get the socialmedia link based on key and active language
     */
    const getSocialLink = useCallback(
        (key) => {
            const href = UtilService.footerLink(key, LocaleService.getCurrentLanguage());
            return href;
        },
        []
    );

    /*
     To translate static text based on active language
     */
    const getContent = useCallback(
        (key) => {
            switch (key) {
                case 'workplaceAccident':
                    return translator(message.workplaceAccidents);
                case 'callUs':
                    return translator(message.callUs);
                case 'callUsNumber':
                    return translator(message.callUsNumber);
                case 'ContactUsNumber':
                    return translator(message.ContactUsNumber);
                case 'contactNumber':
                    return translator(message.contactNumber);
                case 'footerCopyright': {
                    // get the current datetime and then get the 3 digit year
                    const currentYear = new Date().getFullYear().toString();
                    return translator(message.footerCopyright, {0: currentYear});
                }
                default: '';  // if key is unknown, better return empty string
            }
            return null;
        },
        [translator]
    );

    /*
     *Component level overrides
     */
    const overrides = {
        safetyCheckLink: {
            content: getLink('safetyCheck')
        },
        healthAndSafetyIndexLink: {
            content: getLink('healthAndSafetyIndex')
        },
        careersLink: {
            content: getLink('careers')
        },
        termsOfUseLink: {
            content: getLink('termsOfUse')
        },
        contactUsLink: {
            content: getLink('contactUs')
        },
        dayOfMourningLink: {
            content: getLink('dayOfMourning')
        },
        openDataLink: {
            content: getLink('openData')
        },
        accessibilityLink: {
            content: getLink('accessibility')
        },
        privacyLink: {
            content: getLink('privacy')
        },
        fairPracticesCommissionLink: {
            content: getLink('fairPracticesCommission')
        },
        otherLanguagesLink: {
            content: getLink('otherLanguages')
        },
        landAcknowledgeLink: {
            content: getLink('landAcknowledge')
        },
        workplaceAccidentsLabel: {
            content: getContent('workplaceAccident')
        },
        callUsLabel: {
            content: getContent('callUs')
        },
        callUsNumberLink: {
            content: getContent('callUsNumber'),
            title: translator(message.phoneNumberLinkTitle)
        },
        contactUsLabel: {
            content: getContent('ContactUsNumber')
        },
        contactNumberLabel: {
            content: getContent('contactNumber'),
            title: translator(message.phoneNumberLinkTitle)
        },
        footerCopyrightLabel: {
            content: getContent('footerCopyright')
        },
        twitterLink: {
            href: getSocialLink('twitter'),
            target: '_blank',
            'aria-label': UtilService.fetchAriaLabel('twitter', LocaleService.getCurrentLanguage()),
        },
        youtubeLink: {
            href: getSocialLink('youtube'),
            target: '_blank',
            'aria-label': UtilService.fetchAriaLabel('youtube', LocaleService.getCurrentLanguage()),
        },
        linkedinLink: {
            href: getSocialLink('linkedin'),
            target: '_blank',
            'aria-label': UtilService.fetchAriaLabel('linkedin', LocaleService.getCurrentLanguage()),
        },
        instagramLink: {
            href: getSocialLink('instagram'),
            target: '_blank',
            'aria-label': UtilService.fetchAriaLabel('instagram', LocaleService.getCurrentLanguage()),
        },
    };

    /*
     * Component level resolvers
     */
    const resolvers = {
        resolveClassNameMap: styles,
    };

    return renderContentFromMetadata(metadata.footer, overrides, resolvers);
}
