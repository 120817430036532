import { Component } from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends Component {
    /**
     * @memberof gw-portals-error-react.ErrorBoundary
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {boolean} propTypes.resetError - Should remove error once handled
     * @prop {function} propTypes.onError - callback to handle the error
     * @prop {boolean} propTypes.shouldSetUnhandledRejection - should set
     *  window.onunhandledrejection
     */
    static propTypes = {
        resetError: PropTypes.bool,
        onError: PropTypes.func.isRequired,
        shouldSetUnhandledRejection: PropTypes.bool,
        children: PropTypes.node
    };

    static defaultProps = {
        resetError: true,
        shouldSetUnhandledRejection: true,
        children: undefined
    };

    state = {
        error: null,
        handlingError: false,
        initialOnUnhandledRejection: null,
    };

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidMount() {
        const { shouldSetUnhandledRejection } = this.props;

        if (shouldSetUnhandledRejection) {
            if (window.onunhandledrejection) {
                // store old value to be able to reset when component is unmounted
                this.setState({ initialOnUnhandledRejection: window.onunhandledrejection });
            }
            window.onunhandledrejection = this.handlePromiseError;
        }
    }

    componentDidUpdate() {
        const { error, renderError } = this.state;

        // clear renderError if it has already been executed
        if (error === null && renderError) {
            // eslint doesn't like infinite loops.
            // As this is in an if statement, it shouldn't cause one
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ renderError: null });
        }
    }

    componentWillUnmount = () => {
        const { shouldSetUnhandledRejection } = this.props;
        const { initialOnUnhandledRejection } = this.state;

        if (shouldSetUnhandledRejection) {
            // set window.onunhandledrejection to the original
            // value when component that changed it is unmounted
            window.onunhandledrejection = initialOnUnhandledRejection;
        }
    }

    handleError = () => {
        const { onError, resetError } = this.props;
        const { error } = this.state;

        if (onError) {
            this.setState({ handlingError: true });
            Promise.resolve(onError(error)).then((errorToRender) => {
                this.setState({
                    renderError: errorToRender,
                });
            }).finally(() => {
                if (resetError) {
                    this.setState({ error: null, handlingError: false });
                }
            });
        } else {
            // Error not being handled so ignoring the error to end loop
            this.setState({ error: null });
            // eslint-disable-next-line no-console
            console.error(error.message);
        }
    }

    handlePromiseError = (event) => {
        this.setState({ error: event.reason });
        event.preventDefault();
    };

    render() {
        const { children } = this.props;
        const { renderError, error, handlingError } = this.state;

        if (renderError) {
            return renderError;
        }

        if (error && !handlingError) {
            this.handleError();
        }

        if (error || handlingError) {
            return null;
        }

        return children;
    }
}
