import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { CheckboxField, Money, Loader } from '@jutro/components';
import { IntlContext } from '@jutro/locale';
import styles from './ClauseComponent.module.scss';

class ClauseComponent extends Component {
    /**
     * @memberof gw-components-platform-react.ClauseComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.displayName - name of clause to display
     * @prop {bool} propTypes.readOnly - if the clause selection is read only
     * @prop {bool} propTypes.value - is the clause is seleceted
     * @prop {function} propTypes.onValueChange - callback when change is made
     * @prop {string} propTypes.path - path to value in the view modal
     * @prop {bool} propTypes.isLoading - should the clause be loading
     * @prop {string} propTypes.loadingMessage - message to be shown while loading
     * @prop {string} propTypes.containerClassName - clause container class
     * @prop {bool} propTypes.showAmount - determine to show amount next to displayName
     */

    static contextType = IntlContext;

    static propTypes = {
        id: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        readOnly: PropTypes.bool,
        onValueChange: PropTypes.func.isRequired,
        children: PropTypes.arrayOf(PropTypes.shape({})),
        value: PropTypes.bool,
        path: PropTypes.string,
        isLoading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        checked: PropTypes.bool,
        isEditable: PropTypes.bool,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        containerClassName: PropTypes.string,
        amount: PropTypes.arrayOf(PropTypes.shape({})),
        showAmount: PropTypes.bool
    };

    static defaultProps = {
        readOnly: false,
        path: undefined,
        value: undefined,
        isLoading: false,
        loadingMessage: '',
        checked: false,
        children: undefined,
        isEditable: true,
        description: undefined,
        containerClassName: undefined,
        amount: undefined,
        showAmount: true
    };

    handleChange = (value) => {
        const { onValueChange, path } = this.props;

        if (onValueChange) {
            onValueChange(value, path);
        }
    };

    getLabelNameWithAmount = (labelName, labelAmount) => {
        const { showAmount } = this.props;

        if (!_.isUndefined(labelAmount) && showAmount) {
            const intl = this.context;
            const formatterCurrency = intl.formatNumber(
                labelAmount.amount,
                {
                    style: 'currency',
                    currency: labelAmount.currency,
                    currencyDisplay: 'code'
                }
            );
            return `${labelName} ${formatterCurrency}`;
        }
        return labelName;
    };

    renderEditableValue() {
        const {
            displayName,
            readOnly,
            value,
            id,
            isLoading,
            loadingMessage,
            children,
            path,
            checked,
            description,
            containerClassName,
            amount
        } = this.props;

        const hasChildren = !_.isNil(children);
        const clauseContainerStyles = classNames(styles.clause, containerClassName);
        const checkboxControlStyles = classNames({
            [styles.clauseAndTermSpacing]: hasChildren
        });
        const checkboxStyles = classNames({
            [styles.clauseNoPadding]: !hasChildren
        });

        return (
            <div className={clauseContainerStyles}>
                {isLoading ? (
                    <Loader loaded={!isLoading} text={loadingMessage} />
                ) : (
                    <CheckboxField
                        id={id}
                        label={this.getLabelNameWithAmount(displayName, amount)}
                        value={value}
                        readOnly={readOnly}
                        onValueChange={this.handleChange}
                        className={checkboxStyles}
                        controlClassName={checkboxControlStyles}
                        path={path}
                        checked={checked}
                        showInlineLabel
                        tooltip={!_.isUndefined(description) ? { text: description } : null}
                        layout="full-width"
                    >
                        {value ? children : null}
                    </CheckboxField>
                )}
            </div>
        );
    }

    renderReadOnlyValue() {
        const { displayName, children, amount } = this.props;

        return (
            <div className={styles.readOnlyGrid}>
                <span className={styles.readOnlyGridSlotLeft}>
                    {displayName}
                    <span className={styles.readOnlyGridAmount}>
                        {!_.isUndefined(amount) ? (
                            <Money
                                amount={amount.amount}
                                currency={amount.currency}
                                showFractions
                            />
                        ) : undefined}
                    </span>
                </span>
                <div className={styles.readOnlyGridSlotRight}>{children}</div>
            </div>
        );
    }

    render() {
        const { isEditable } = this.props;
        return isEditable ? this.renderEditableValue() : this.renderReadOnlyValue();
    }
}

export default ClauseComponent;
