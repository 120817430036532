import { defineMessages } from 'react-intl';

export default defineMessages({
    forgotYourPassword: {
        id: 'auth.components.ForgotPassword.Forgot your password?',
        defaultMessage: 'Forgot your password?'
    },
    forgotYourPasswordDescription: {
        id: 'auth.components.ForgotPassword.Enter your email address below and we’ll send a link to reset your current password.',
        defaultMessage: 'Enter your email address below and we’ll send a link to reset your current password.'
    },
    fieldIncorrect: {
        id: 'auth.components.ForgotPassword.Enter your email address below.',
        defaultMessage: 'Enter your email address below.'
    },
    invalidAttempt: {
        id: 'auth.components.ForgotPassword.Invalid Attempt',
        defaultMessage: 'Invalid Attempt'
    },
    email: {
        id: 'auth.components.ForgotPassword.Email:',
        defaultMessage: 'Email:'
    },
    backToSignIn: {
        id: 'auth.components.ForgotPassword.Back to Sign In',
        defaultMessage: 'Back to Sign In'
    },
    send: {
        id: 'auth.components.ForgotPassword.Send',
        defaultMessage: 'Send'
    },
    checkYourEmail: {
        id: 'auth.components.ForgotPassword.Check your email',
        defaultMessage: 'Check your email'
    },
    linkHasBeenSent: {
        id: 'auth.components.ForgotPassword.A password reset link has been sent. Click the link in the email message to reset your password.',
        defaultMessage: 'A password reset link has been sent. Click the link in the email message to reset your password.'
    },
    invalidEmailAttempt: {
        id: 'auth.components.ForgotPassword.Invalid attempt',
        defaultMessage: 'Invalid attempt'
    },
    noEmailMatch: {
        id: 'auth.components.ForgotPassword.We could not match an account with the email address you entered, please try again.',
        defaultMessage: 'We could not match an account with the email address you entered, please try again.'
    },
    unableToProcessRequest: {
        id: 'auth.components.ForgotPassword.Unable to process your request',
        defaultMessage: 'Unable to process your request'
    }
});
