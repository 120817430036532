/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import _ from 'lodash';
import {
    ModalNextProvider,
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
// importing TranslatorContext and LocaleService
import { TranslatorContext, LocaleService } from '@jutro/locale';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import config from 'app-config';
import styles from './session-timeout.module.scss';
import messages from './session-timeout.messages';
// importing UtilService
import UtilService from '../../services/UtilService';
import { useAuthentication } from 'gw-digital-auth-react';

function InactiveSessionConfirmModal(props) {
    const {
        isOpen,
        onResolve,
        logoutConfirmationInterval
    } = props;

    const auth = useAuthentication();
    const history = useHistory();
    const { sessionConfig } = config;
    const translator = useContext(TranslatorContext);

    // this formats string with variables
    if (!String.prototype.format) {
        // eslint-disable-next-line no-extend-native
        String.prototype.format = function () {
            const args = arguments;
            return this.replace(/{(\d+)}/g, (match, number) => {
                return typeof args[number] !== 'undefined'
                    ? args[number]
                    : match;
            });
        };
    }

    // This function is used to convert minutes to mm:ss
    function minutesToHHmm(minutes) {
        seconds = minutes * 60;
        const sec_num = parseInt(seconds, 10);
        var minutes = Math.floor((sec_num) / 60);
        var seconds = sec_num - (minutes * 60);

        if (minutes < 10) { minutes = `${minutes}`; }
        if (seconds < 10) { seconds = `0${seconds}`; }
        return `${minutes}:${seconds}`;
    }

    let minutesTimer = _.get(sessionConfig, 'logoutConfirmationIntervalMins');
    const expiryMessagei18n = (` ${translator(messages.warningAboutToExpire)}`).slice(1);
    const [expiryMessage, setExpiryMessage] = useState(expiryMessagei18n
        .format(minutesToHHmm(minutesTimer)));


    useEffect(() => {
        const logoutTimerInterval = setInterval(() => {
            setExpiryMessage(() => {
                minutesTimer = ((minutesTimer * 60) - 1) / 60;
                const WarningMessage = expiryMessagei18n.format(minutesToHHmm(minutesTimer));
                return WarningMessage;
            });
            if (minutesTimer <= 0) {
                clearInterval(logoutTimerInterval);
            }
        }, 1000);
        const closeModal = setTimeout(onResolve, logoutConfirmationInterval);
        return () => {
            window.clearTimeout(closeModal);
        };
    }, [logoutConfirmationInterval, onResolve]);

    const onLeaveSessionClick = useCallback(() => {
        auth.logout().then(() => {
            UtilService.deleteAllCookies();
            sessionStorage.clear();
            window.location.href = UtilService.unifiedLogoutSessionExpired(LocaleService.getStoredLanguage());
        });
    }, [auth, history]);

    return (
        <ModalNext
            isOpen={isOpen}
            shouldCloseOnOverlayClick={false}
            ariaLabelledby="sessionExpiryHeader"
            ariaDescribedby="sessionExpiryDescription"
        >
            <ModalHeader title={<h2 id='sessionExpiryHeader' className="diModalHeader">{translator(messages.sessionTimeout)}</h2>} />
            <ModalBody>
                <div id='sessionExpiryDescription' className="diModalContent">{expiryMessage}</div>
            </ModalBody>
            <ModalFooter>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{ marginBottom: '30px' }}>
                        <Button id="sessionSecButton"  className="normalSpace diButtonWidth" type="secondary" onClick={onResolve}>
                            {translator(messages.extendSession)}
                        </Button>
                    </span>
                    <span>
                        <Button id="sessionPriButton" className="normalSpace diButtonWidth" type="primary" onClick={() => {
                            onLeaveSessionClick();
                            onResolve();
                        }}>
                            {translator(messages.leaveSession)}
                        </Button>                                           
                    </span>
                </div>
            </ModalFooter>
        </ModalNext>
    );
}

InactiveSessionConfirmModal.propTypes = {
    isOpen: PropTypes.string.isRequired,
    logoutConfirmationInterval: PropTypes.number.isRequired,
    onResolve: PropTypes.func.isRequired
};

function SessionTimeoutModal(resetInactivityTimer, logoutConfirmationInterval) {
    ModalNextProvider.showModal(
        <InactiveSessionConfirmModal
            logoutConfirmationInterval={logoutConfirmationInterval}
        />
    ).then(() => {
        resetInactivityTimer();
    }, _.noop);
}

export default SessionTimeoutModal;
