import { defineMessages } from 'react-intl';

export default defineMessages({
    sessionTimeout: {
        id: 'auth.components.logout-warning.Session timeout',
        defaultMessage: 'Session timeout'
    },
    warningAboutToExpire: {
        id: 'auth.components.logout-warning.Warning, your session is about to expire',
        defaultMessage: 'Warning, your session is about to expire'
    },
    continueSession: {
        id: 'auth.components.logout-warning.Continue Session',
        defaultMessage: 'Continue Session'
    }
});
