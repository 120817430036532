// This service is used to implement google analytics tags
import { LocaleService } from '@jutro/locale';

class AnalyticsLinkService {
    static contentFrench = {
        yourclaim: 'CH_claim_fr',
        previous: 'AC_prev_fr',
        viewclaim: 'AC_view_fr',
        returnToClaimHome: 'AC_home_fr',
        switchClaim: 'TS_switch_fr',
        myClaimOverview: 'Claim Overview_fr',
        ClaimNumber: 'MP_selectClaim_fr',
        referenceNumber: 'OSPCDashboard_ReferenceNumber',
        referenceDraft: 'OSPCDashboard_ReferenceDraft',
        submitNewReport: 'OSPCDashboard_SubmitNewReport',
        deleteDraft: 'OSPCDashboard_DeleteDraft',
        formsForPeopleWithClaims: 'HL_forms_fr',
        benefitsOverview: 'HL_benefit_fr',
        prescriptionDrugExpensesAndOtherHealthCareResources: 'HL_HCR_fr',
        returnToWork: 'HL_RTW_fr',
        disagreeingWithADecisionWeHaveMade: 'HL_appeal_fr',
        yourGuideBenefitsServicesAndResponsibilities: 'HL_guide_fr',
        leftPanelHelpfullink: 'Helpful Links_fr',
        nonHealthCareBenefits: 'NB_nonHC_fr',
        healthCareBenefits: 'NB_HC_fr',
        benefitPayment: 'NB_benefit_fr',
        uploadDocument: 'NB_uploadDoc_fr',
        submittedDocuments: 'NB_submitDoc_fr',
        sendUsAMessage: 'NB_message_fr',
        mail: 'NB_mail_fr',
        ClaimEnrollmentFailure: 'CE_Failure_fr',
        DocumentuploadSubmit: 'Submit_fr',
        DocumentuploadConfirm: 'DocumentUpload_Confirmation_fr',
        DocumentDeclarationClicked: 'Declaration_fr',
        MyProfile: 'My Profile_fr',
        directDepo: 'DD_fr',
        directDepoAdd: 'DD_add_fr',
        directDepoNew: 'DD_new_fr',
        directDepoView: 'DD_view_fr',
        directDepoEdit: 'DD_edit_fr',
        directDepoError: 'DD_error_fr',
        travelbook: 'Travel_book_fr',
        appeals: 'Appeals_PageView_Fr',
        intentToObjectForm: 'Appeals_intent_to_object_form_Fr',
        wsibCaAppeals: 'Appeals_wsib.ca_appeals_Fr',
        appeals5To7Weeks: 'Appeals_5-7_weeks_More_1_Fr',
        appeals6To8Weeks: 'Appeals_6-8_weeks_More_2_Fr',
        appeals6To8Weeks3: 'Appeals_6-8_weeks_More_3_Fr',
        appeals30CalendarDays: 'Appeals_30_calendar_days_More_4_Fr',
        appealsOutcome: 'Appeals_outcome_appeal_More_5_Fr',

        privacyPolicyNotAccepted: 'Error_PrivacyNotAccepted',
        whatIsAcnTooltipClick: 'AssociatedClaimNumber_AssociatedClaimTooltip',
        noPhoneHelpClick: 'PWIContact_NoPhoneNumber',
        noSinHelpClick: 'PWIAdditional_SIN',

        cancelSubmissionReportType: 'SelectReportType_CancelSubmission',
        cancelSubmissionExposureForm: 'ExposureForm_CancelSubmission',
        cancelSubmissionInjuryIllnessCategory: 'SelectCategoryInjuryIllness_CancelSubmission',
        cancelSubmissionExposureIncidentCategory: 'SelectCategoryExposure_CancelSubmission',
        cancelSubmissionACN: 'AssociatedClaimNumber_CancelSubmission',
        cancelSubmissionNatureOfInjuryPhysical: 'SelectNOIPhysical_CancelSubmission',
        physicalOneTimeOccurence: 'SelectNOIPhysical_OneTimeOccurence',
        physicalNoneApply: 'SelectNOIPhysical_NoneApply',
        editReportType: 'ReviewSubmit_EditReportType',
        cancelSubmissionNatureOfInjuryMental: 'SelectNOIMental_CancelSubmission',
        cancelSubmissionPwiContact: 'PWIContact_CancelSubmission',
        cancelSubmissionPwiAdditionalInfo: 'PWIAdditional_CancelSubmission',
        additionalGender: 'PWIAdditional_Gender',
        cancelSubmissionAddress: 'PWIAddress_CancelSubmission',
        cancelSubmissionEmployerInfo: 'EmployerInformation_CancelSubmission',
        cancelSubmissionPWIUnderage: 'PWIUnderage_CancelSubmission',
        underageConsentTooltip: 'PWIUnderage_ParentGuardianConsent',
        witnessInvolved: 'Witness_Involved',
        lostTimeRetired: 'LostTime_Retired',
        supportingDocRepresentative: 'SupportingDoc_DOA_Representative',
        LostTimeIrregularHours: 'LostTime_IrregularHours',

        pageViewPrivacyPolicy: 'Privacy_PageView',
        pageViewReportType: 'SelectReportType_PageView',
        pageViewExposureForm: 'ExposureForm_PageView',
        pageViewInjuryIllnessCategory: 'SelectCategoryInjuryIllness_PageView',
        pageViewNihl: 'NIHL_PageView',
        pageViewExposureIncidentCategory: 'SelectCategoryExposure_PageView',
        pageViewACN: 'AssociatedClaimNumber_PageView',
        pageViewNatureOfInjuryPhysical: 'SelectNOIPhysical_PageView',
        pageViewNatureOfInjuryMental: 'SelectNOIMental_PageView',
        pageViewPwiContact: 'PWIContact_PageView',
        pageViewPwiAdditionalInfo: 'PWIAdditional_PageView',
        pageViewPwiAddress: 'PWIAddress_PageView',
        pageViewEmployerInfo: 'EmployerInformation_PageView',
        pageViewOnboardingComplete: 'OnboardingComplete_PageView',
        pageViewOnboardingCompleteExposure: 'OnboardingCompleteExposure_PageView',
        pageViewWorkplaceDetailsExposure: 'WorkHistoryExposure_PageView',
        pageViewWorkHistoryRelationship: 'Relationship_PageView',
        pageViewWorkHistoryCier: 'HealthSafetyCEIR_PageView',
        pageViewPWIUnderage: 'PWIUnderage_PageView',
        pageViewReturnPrivacyPolicy: 'PrivacyReturn_PageView',
        pageViewDateReportedToEmployer: 'InjuryReported_PageView',
        pageViewInjuryWitnessInfo: 'Witness_PageView',
        pageViewPriorSimilarCondition: 'RelatedProblem_PageView',
        pageViewIdentifyHealthcareReceived: 'Healthcare_PageView',
        pageViewDateOfLostTime: 'LostTimeToday_PageView',
        pageViewLostTimeModifiedWork: 'LostTimeModifiedWork_PageView',
        pageViewLostTimeReturnToWork: 'LostTimeReturnToWork_PageView',
        pageViewAdditionalBenefitDuringRecovery: 'LostTimeRecovery_PageView',
        pageViewWorkHistory: 'WorkHistory_PageView',

        PrivacyPolicySection_TimeSpent: 'PrivacyPolicySection_TimeSpent',
        ReportTypeSection_TimeSpent: 'ReportTypeSection_TimeSpent',
        PersonalDetailsSection_TimeSpent: 'PersonalDetailsSection_TimeSpent',
        EmployerDetailsSection_TimeSpent: 'EmployerDetailsSection_TimeSpent',
        WorkHistorySection_TimeSpent: 'WorkHistorySection_TimeSpent',
        InjuryDetailsSection_TimeSpent: 'InjuryDetailsSection_TimeSpent',
        HealthCareSection_TimeSpent: 'HealthCareSection_TimeSpent',
        LostTimeAndEarningsSection_TimeSpent: 'LostTimeAndEarningsSection_TimeSpent',
        ReviewAndSubmitSection_TimeSpent: 'ReviewAndSubmitSection_TimeSpent',
        SupportingDocSection_TimeSpent: 'SupportingDocSection_TimeSpent',
        ExposureDetailsSection_TimeSpent: 'ExposureDetailsSection_TimeSpent',

        Privacy_TimeSpent: 'Privacy_TimeSpent',
        SelectReportType_TimeSpent: 'SelectReportType_TimeSpent',
        SelectCategoryInjuryIllness_TimeSpent: 'SelectCategoryInjuryIllness_TimeSpent',
        ExposureForm_TimeSpent: 'ExposureForm_TimeSpent',
        SelectCategoryExposure_TimeSpent: 'SelectCategoryExposure_TimeSpent',
        SelectNOIPhysical_TimeSpent: 'SelectNOIPhysical_TimeSpent',
        SelectNOIMental_TimeSpent: 'SelectNOIMental_TimeSpent',
        NIHL_TimeSpent: 'NIHL_TimeSpent',
        AssociatedClaimNumber_TimeSpent: 'AssociatedClaimNumber_TimeSpent',
        PWIContact_TimeSpent: 'PWIContact_TimeSpent',
        PWIAdditional_TimeSpent: 'PWIAdditional_TimeSpent',
        PWIUnderage_TimeSpent: 'PWIUnderage_TimeSpent',
        PWIAddress_TimeSpent: 'PWIAddress_TimeSpent',
        EmployerInformation_TimeSpent: 'EmployerInformation_TimeSpent',
        OnboardingComplete_TimeSpent: 'OnboardingComplete_TimeSpent',
        WorkHistory_TimeSpent: 'WorkHistory_TimeSpent',
        WorkHistoryExposure_TimeSpent: 'WorkHistoryExposure_TimeSpent',
        Relationship_TimeSpent: 'Relationship_TimeSpent',
        HealthSafetyCEIR_TimeSpent: 'HealthSafetyCEIR_TimeSpent',
        ExposureDetails_TimeSpent: 'ExposureDetails_TimeSpent',
        InjuryDetailPhysical_TimeSpent: 'InjuryDetailPhysical_TimeSpent',
        InjuryDetailGradual_TimeSpent: 'InjuryDetailGradual_TimeSpent',
        InjuryDetailIllness_TimeSpent: 'InjuryDetailIllness_TimeSpent',
        InjuryDetailsMental_TimeSpent: 'InjuryDetailsMental_TimeSpent',
        InjuryDatePhysical_TimeSpent: 'InjuryDatePhysical_TimeSpent',
        InjuryDateGradual_TimeSpent: 'InjuryDateGradual_TimeSpent',
        InjuryDateIllness_TimeSpent: 'InjuryDateIllness_TimeSpent',
        InjuryDateMental_TimeSpent: 'InjuryDateMental_TimeSpent',
        BodyPartInjury_TimeSpent: 'BodyPartInjury_TimeSpent',
        BodyPartMental_TimeSpent: 'BodyPartMental_TimeSpent',
        InjuryReported_TimeSpent: 'InjuryReported_TimeSpent',
        Witness_TimeSpent: 'Witness_TimeSpent',
        IncidentLocation_TimeSpent: 'IncidentLocation_TimeSpent',
        RelatedProblem_TimeSpent: 'RelatedProblem_TimeSpent',
        Healthcare_TimeSpent: 'Healthcare_TimeSpent',
        LostTime_TimeSpent: 'LostTime_TimeSpent',
        LostTimeModifiedWork_TimeSpent: 'LostTimeModifiedWork_TimeSpent',
        LostTimeReturnToWork_TimeSpent: 'LostTimeReturnToWork_TimeSpent',
        LostTimePay_TimeSpent: 'LostTimePay_TimeSpent',
        LostTimeRecovery_TimeSpent: 'LostTimeRecovery_TimeSpent',
        SupportingDoc_DOA_TimeSpent: 'SupportingDoc_DOA_TimeSpent',
        SupportingDoc_TimeSpent: 'SupportingDoc_TimeSpent',
        AdditionalComments_TimeSpent: 'AdditionalComments_TimeSpent',
        AutoEnroll_TimeSpent: 'AutoEnroll_TimeSpent',
        ReviewSubmit_TimeSpent: 'ReviewSubmit_TimeSpent',
        Complete_TimeSpent: 'Complete_TimeSpent',
        pageViewAutoEnroll: 'AutoEnroll_PageView',
        pageViewConfirmation: 'Complete_PageView',
        additionalComments: 'AdditionalComments_PageView',
        uploadDirectionOfAuthorization: 'SupportingDoc_DOA_PageView',
        supportingDocuments: 'SupportingDoc_PageView',
        ExposureDetails_EditReportType: 'ExposureDetails_EditReportType',
        HealthSafetyCEIR_UploadDoc: 'HealthSafetyCEIR_UploadDoc',

        ExposureDetails_PageView: 'ExposureDetails_PageView',
        IncidentLocationExposure_PageView: 'IncidentLocationExposure_PageView',
        InjuryDetailIllness_PageView: 'InjuryDetailIllness_PageView',
        ReviewSubmit_PageView: 'ReviewSubmit_PageView',
        BodyPartInjury_PageView: 'BodyPartInjury_PageView',
        InjuryDetailPhysical_PageView: 'InjuryDetailPhysical_PageView',
        InjuryDatePhysical_PageView: 'InjuryDatePhysical_PageView',
        InjuryDetailGradual_PageView: 'InjuryDetailGradual_PageView',
        InjuryDateGradual_PageView: 'InjuryDateGradual_PageView',
        InjuryDateIllness_PageView: 'InjuryDateIllness_PageView',
        InjuryDetailsMental_PageView: 'InjuryDetailsMental_PageView',
        InjuryDateMental_PageView: 'InjuryDateMental_PageView',
        BodyPartMental_PageView: 'BodyPartMental_PageView',
        LostTimePay_PageView: 'LostTimePay_PageView',
        InjuryDetailIllness_EditReportType: 'InjuryDetailIllness_EditReportType',
        BodyPartInjury_UploadDoc: 'BodyPartInjury_UploadDoc',
        BodyPartMental_UploadDoc: 'BodyPartMental_UploadDoc',
        LostTimePay_UploadDoc: 'LostTimePay_UploadDoc',
        ReviewSubmitUnderage_PageView: 'ReviewSubmitUnderage_PageView',

        ToolTip_SelectNOIMental_SingleEvent: 'SelectNOIMental_SingleEvent',
        ToolTip_SelectNOIMental_Harassment: 'SelectNOIMental_Harassment',
        ToolTip_SelectNOIMental_CumulativeEvent: 'SelectNOIMental_CumulativeEvent',
        ToolTip_Relationship_Tooltip1: 'Relationship_Tooltip1',
        ToolTip_Relationship_Tooltip2: 'Relationship_Tooltip2',
        ToolTip_Relationship_Tooltip3: 'Relationship_Tooltip3',
        ToolTip_Relationship_Tooltip4: 'Relationship_Tooltip4',
        ToolTip_Relationship_Tooltip5: 'Relationship_Tooltip5',
        ToolTip_Relationship_Tooltip6: 'Relationship_Tooltip6',
        ToolTip_Relationship_Tooltip7: 'Relationship_Tooltip7',
        ToolTip_Relationship_Tooltip8: 'Relationship_Tooltip8',
        ToolTip_Relationship_Tooltip9: 'Relationship_Tooltip9',
        ToolTip_Relationship_Tooltip10: 'Relationship_Tooltip10',
        ToolTip_Relationship_Tooltip11: 'Relationship_Tooltip11',
        ToolTip_Relationship_Tooltip12: 'Relationship_Tooltip12',
        ToolTip_Relationship_Tooltip13: 'Relationship_Tooltip13',
        ToolTip_Relationship_Tooltip14: 'Relationship_Tooltip14',
        ToolTip_Relationship_Tooltip15: 'Relationship_Tooltip15',
        ToolTip_Relationship_Tooltip16: 'Relationship_Tooltip16',
        HelpMenu_HelpIcon: 'HelpMenu_HelpIcon',
        HelpMenu_FAQ: 'HelpMenu_FAQ',
        HelpMenu_CallSupport: 'HelpMenu_CallSupport',
        InjuryDetailGradual_EditReportType: 'InjuryDetailGradual_EditReportType',
        InjuryDetailPhysical_EditReportType: 'InjuryDetailPhysical_EditReportType',
        InjuryDetailMental_EditReportType: 'InjuryDetailMental_EditReportType',
        Error_reCAPTCHA: 'Error_reCAPTCHA',
        Error_403: 'Error_403',
        Error_Concurrency: 'Error_Concurrency',
        Error_General: 'Error_General',
        Error_MandatoryFieldBlank: 'Error_MandatoryFieldBlank',
        SupportingDoc_UploadDoc: 'SupportingDoc_UploadDoc',
        SupportingDoc_DOA_UploadDoc: 'SupportingDoc_DOA_UploadDoc'
    };

    static contentEnglish = {
        yourclaim: 'CH_claim',
        previous: 'AC_prev',
        viewclaim: 'AC_view',
        returnToClaimHome: 'AC_home',
        switchClaim: 'TS_switch',
        myClaimOverview: 'Claim Overview',
        ClaimNumber: 'MP_selectClaim',
        referenceNumber: 'OSPCDashboard_ReferenceNumber',
        referenceDraft: 'OSPCDashboard_ReferenceDraft',
        submitNewReport: 'OSPCDashboard_SubmitNewReport',
        deleteDraft: 'OSPCDashboard_DeleteDraft',
        formsForPeopleWithClaims: 'HL_forms',
        benefitsOverview: 'HL_benefit',
        prescriptionDrugExpensesAndOtherHealthCareResources: 'HL_HCR',
        returnToWork: 'HL_RTW',
        disagreeingWithADecisionWeHaveMade: 'HL_appeal',
        yourGuideBenefitsServicesAndResponsibilities: 'HL_guide',
        leftPanelHelpfullink: 'Helpful Links',
        healthCareBenefits: 'NB_HC',
        nonHealthCareBenefits: 'NB_nonHC',
        benefitPayment: 'NB_benefit',
        uploadDocument: 'NB_uploadDoc',
        submittedDocuments: 'NB_submitDoc',
        sendUsAMessage: 'NB_message',
        mail: 'NB_mail',
        ClaimEnrollmentFailure: 'CE_Failure',
        DocumentuploadSubmit: 'Submit',
        DocumentuploadConfirm: 'DocumentUpload_Confirmation',
        DocumentDeclarationClicked: 'Declaration',
        MyProfile: 'My Profile',
        directDepo: 'DD',
        directDepoAdd: 'DD_add',
        directDepoNew: 'DD_new',
        directDepoView: 'DD_view',
        directDepoEdit: 'DD_edit',
        directDepoError: 'DD_error',
        travelbook: 'Travel_book',
        appeals: 'Appeals_PageView',
        intentToObjectForm: 'Appeals_intent_to_object_form',
        wsibCaAppeals: 'Appeals_wsib.ca_appeals',
        appeals5To7Weeks: 'Appeals_5-7_weeks_More_1',
        appeals6To8Weeks: 'Appeals_6-8_weeks_More_2',
        appeals6To8Weeks3: 'Appeals_6-8_weeks_More_3',
        appeals30CalendarDays: 'Appeals_30_calendar_days_More_4',
        appealsOutcome: 'Appeals_outcome_appeal_More_5',

        privacyPolicyNotAccepted: 'Error_PrivacyNotAccepted',
        whatIsAcnTooltipClick: 'AssociatedClaimNumber_AssociatedClaimTooltip',
        noPhoneHelpClick: 'PWIContact_NoPhoneNumber',
        noSinHelpClick: 'PWIAdditional_SIN',

        cancelSubmissionReportType: 'SelectReportType_CancelSubmission',
        cancelSubmissionExposureForm: 'ExposureForm_CancelSubmission',
        cancelSubmissionInjuryIllnessCategory: 'SelectCategoryInjuryIllness_CancelSubmission',
        cancelSubmissionExposureIncidentCategory: 'SelectCategoryExposure_CancelSubmission',
        cancelSubmissionACN: 'AssociatedClaimNumber_CancelSubmission',
        cancelSubmissionNatureOfInjuryPhysical: 'SelectNOIPhysical_CancelSubmission',
        physicalOneTimeOccurence: 'SelectNOIPhysical_OneTimeOccurence',
        physicalNoneApply: 'SelectNOIPhysical_NoneApply',
        editReportType: 'ReviewSubmit_EditReportType',
        cancelSubmissionNatureOfInjuryMental: 'SelectNOIMental_CancelSubmission',
        cancelSubmissionPwiContact: 'PWIContact_CancelSubmission',
        cancelSubmissionPwiAdditionalInfo: 'PWIAdditional_CancelSubmission',
        additionalGender: 'PWIAdditional_Gender',
        cancelSubmissionAddress: 'PWIAddress_CancelSubmission',
        cancelSubmissionEmployerInfo: 'EmployerInformation_CancelSubmission',
        cancelSubmissionPWIUnderage: 'PWIUnderage_CancelSubmission',
        underageConsentTooltip: 'PWIUnderage_ParentGuardianConsent',
        witnessInvolved: 'Witness_Involved',
        lostTimeRetired: 'LostTime_Retired',
        supportingDocRepresentative: 'SupportingDoc_DOA_Representative',
        LostTimeIrregularHours: 'LostTime_IrregularHours',

        pageViewPrivacyPolicy: 'Privacy_PageView',
        pageViewReportType: 'SelectReportType_PageView',
        pageViewExposureForm: 'ExposureForm_PageView',
        pageViewInjuryIllnessCategory: 'SelectCategoryInjuryIllness_PageView',
        pageViewNihl: 'NIHL_PageView',
        pageViewExposureIncidentCategory: 'SelectCategoryExposure_PageView',
        pageViewACN: 'AssociatedClaimNumber_PageView',
        pageViewNatureOfInjuryPhysical: 'SelectNOIPhysical_PageView',
        pageViewNatureOfInjuryMental: 'SelectNOIMental_PageView',
        pageViewPwiContact: 'PWIContact_PageView',
        pageViewPwiAdditionalInfo: 'PWIAdditional_PageView',
        pageViewPwiAddress: 'PWIAddress_PageView',
        pageViewEmployerInfo: 'EmployerInformation_PageView',
        pageViewOnboardingComplete: 'OnboardingComplete_PageView',
        pageViewOnboardingCompleteExposure: 'OnboardingCompleteExposure_PageView',
        pageViewWorkplaceDetailsExposure: 'WorkHistoryExposure_PageView',
        pageViewWorkHistoryRelationship: 'Relationship_PageView',
        pageViewWorkHistoryCier: 'HealthSafetyCEIR_PageView',
        pageViewPWIUnderage: 'PWIUnderage_PageView',
        pageViewReturnPrivacyPolicy: 'PrivacyReturn_PageView',
        pageViewDateReportedToEmployer: 'InjuryReported_PageView',
        pageViewInjuryWitnessInfo: 'Witness_PageView',
        pageViewPriorSimilarCondition: 'RelatedProblem_PageView',
        pageViewIdentifyHealthcareReceived: 'Healthcare_PageView',
        pageViewDateOfLostTime: 'LostTimeToday_PageView',
        pageViewLostTimeModifiedWork: 'LostTimeModifiedWork_PageView',
        pageViewLostTimeReturnToWork: 'LostTimeReturnToWork_PageView',
        pageViewAdditionalBenefitDuringRecovery: 'LostTimeRecovery_PageView',
        pageViewWorkHistory: 'WorkHistory_PageView',

        PrivacyPolicySection_TimeSpent: 'PrivacyPolicySection_TimeSpent',
        ReportTypeSection_TimeSpent: 'ReportTypeSection_TimeSpent',
        PersonalDetailsSection_TimeSpent: 'PersonalDetailsSection_TimeSpent',
        EmployerDetailsSection_TimeSpent: 'EmployerDetailsSection_TimeSpent',
        WorkHistorySection_TimeSpent: 'WorkHistorySection_TimeSpent',
        InjuryDetailsSection_TimeSpent: 'InjuryDetailsSection_TimeSpent',
        HealthCareSection_TimeSpent: 'HealthCareSection_TimeSpent',
        LostTimeAndEarningsSection_TimeSpent: 'LostTimeAndEarningsSection_TimeSpent',
        ReviewAndSubmitSection_TimeSpent: 'ReviewAndSubmitSection_TimeSpent',
        SupportingDocSection_TimeSpent: 'SupportingDocSection_TimeSpent',
        ExposureDetailsSection_TimeSpent: 'ExposureDetailsSection_TimeSpent',

        Privacy_TimeSpent: 'Privacy_TimeSpent',
        SelectReportType_TimeSpent: 'SelectReportType_TimeSpent',
        SelectCategoryInjuryIllness_TimeSpent: 'SelectCategoryInjuryIllness_TimeSpent',
        ExposureForm_TimeSpent: 'ExposureForm_TimeSpent',
        SelectCategoryExposure_TimeSpent: 'SelectCategoryExposure_TimeSpent',
        SelectNOIPhysical_TimeSpent: 'SelectNOIPhysical_TimeSpent',
        SelectNOIMental_TimeSpent: 'SelectNOIMental_TimeSpent',
        NIHL_TimeSpent: 'NIHL_TimeSpent',
        AssociatedClaimNumber_TimeSpent: 'AssociatedClaimNumber_TimeSpent',
        PWIContact_TimeSpent: 'PWIContact_TimeSpent',
        PWIAdditional_TimeSpent: 'PWIAdditional_TimeSpent',
        PWIUnderage_TimeSpent: 'PWIUnderage_TimeSpent',
        PWIAddress_TimeSpent: 'PWIAddress_TimeSpent',
        EmployerInformation_TimeSpent: 'EmployerInformation_TimeSpent',
        OnboardingComplete_TimeSpent: 'OnboardingComplete_TimeSpent',
        WorkHistory_TimeSpent: 'WorkHistory_TimeSpent',
        WorkHistoryExposure_TimeSpent: 'WorkHistoryExposure_TimeSpent',
        Relationship_TimeSpent: 'Relationship_TimeSpent',
        HealthSafetyCEIR_TimeSpent: 'HealthSafetyCEIR_TimeSpent',
        ExposureDetails_TimeSpent: 'ExposureDetails_TimeSpent',
        InjuryDetailPhysical_TimeSpent: 'InjuryDetailPhysical_TimeSpent',
        InjuryDetailGradual_TimeSpent: 'InjuryDetailGradual_TimeSpent',
        InjuryDetailIllness_TimeSpent: 'InjuryDetailIllness_TimeSpent',
        InjuryDetailsMental_TimeSpent: 'InjuryDetailsMental_TimeSpent',
        InjuryDatePhysical_TimeSpent: 'InjuryDatePhysical_TimeSpent',
        InjuryDateGradual_TimeSpent: 'InjuryDateGradual_TimeSpent',
        InjuryDateIllness_TimeSpent: 'InjuryDateIllness_TimeSpent',
        InjuryDateMental_TimeSpent: 'InjuryDateMental_TimeSpent',
        BodyPartInjury_TimeSpent: 'BodyPartInjury_TimeSpent',
        BodyPartMental_TimeSpent: 'BodyPartMental_TimeSpent',
        InjuryReported_TimeSpent: 'InjuryReported_TimeSpent',
        Witness_TimeSpent: 'Witness_TimeSpent',
        IncidentLocation_TimeSpent: 'IncidentLocation_TimeSpent',
        RelatedProblem_TimeSpent: 'RelatedProblem_TimeSpent',
        Healthcare_TimeSpent: 'Healthcare_TimeSpent',
        LostTime_TimeSpent: 'LostTime_TimeSpent',
        LostTimeModifiedWork_TimeSpent: 'LostTimeModifiedWork_TimeSpent',
        LostTimeReturnToWork_TimeSpent: 'LostTimeReturnToWork_TimeSpent',
        LostTimePay_TimeSpent: 'LostTimePay_TimeSpent',
        LostTimeRecovery_TimeSpent: 'LostTimeRecovery_TimeSpent',
        SupportingDoc_DOA_TimeSpent: 'SupportingDoc_DOA_TimeSpent',
        SupportingDoc_TimeSpent: 'SupportingDoc_TimeSpent',
        AdditionalComments_TimeSpent: 'AdditionalComments_TimeSpent',
        AutoEnroll_TimeSpent: 'AutoEnroll_TimeSpent',
        ReviewSubmit_TimeSpent: 'ReviewSubmit_TimeSpent',
        Complete_TimeSpent: 'Complete_TimeSpent',
        pageViewAutoEnroll: 'AutoEnroll_PageView',
        additionalComments: 'AdditionalComments_PageView',
        pageViewConfirmation: 'Complete_PageView',
        uploadDirectionOfAuthorization: 'SupportingDoc_DOA_PageView',
        supportingDocuments: 'SupportingDoc_PageView',
        ExposureDetails_EditReportType: 'ExposureDetails_EditReportType',
        HealthSafetyCEIR_UploadDoc: 'HealthSafetyCEIR_UploadDoc',

        ExposureDetails_PageView: 'ExposureDetails_PageView',
        IncidentLocationExposure_PageView: 'IncidentLocationExposure_PageView',
        InjuryDetailIllness_PageView: 'InjuryDetailIllness_PageView',
        ReviewSubmit_PageView: 'ReviewSubmit_PageView',
        BodyPartInjury_PageView: 'BodyPartInjury_PageView',
        InjuryDetailPhysical_PageView: 'InjuryDetailPhysical_PageView',
        InjuryDatePhysical_PageView: 'InjuryDatePhysical_PageView',
        InjuryDetailGradual_PageView: 'InjuryDetailGradual_PageView',
        InjuryDateGradual_PageView: 'InjuryDateGradual_PageView',
        InjuryDateIllness_PageView: 'InjuryDateIllness_PageView',
        InjuryDetailsMental_PageView: 'InjuryDetailsMental_PageView',
        InjuryDateMental_PageView: 'InjuryDateMental_PageView',
        BodyPartMental_PageView: 'BodyPartMental_PageView',
        LostTimePay_PageView: 'LostTimePay_PageView',
        InjuryDetailIllness_EditReportType: 'InjuryDetailIllness_EditReportType',
        BodyPartInjury_UploadDoc: 'BodyPartInjury_UploadDoc',
        BodyPartMental_UploadDoc: 'BodyPartMental_UploadDoc',
        LostTimePay_UploadDoc: 'LostTimePay_UploadDoc',
        ReviewSubmitUnderage_PageView: 'ReviewSubmitUnderage_PageView',

        ToolTip_SelectNOIMental_SingleEvent: 'SelectNOIMental_SingleEvent',
        ToolTip_SelectNOIMental_Harassment: 'SelectNOIMental_Harassment',
        ToolTip_SelectNOIMental_CumulativeEvent: 'SelectNOIMental_CumulativeEvent',
        ToolTip_Relationship_Tooltip1: 'Relationship_Tooltip1',
        ToolTip_Relationship_Tooltip2: 'Relationship_Tooltip2',
        ToolTip_Relationship_Tooltip3: 'Relationship_Tooltip3',
        ToolTip_Relationship_Tooltip4: 'Relationship_Tooltip4',
        ToolTip_Relationship_Tooltip5: 'Relationship_Tooltip5',
        ToolTip_Relationship_Tooltip6: 'Relationship_Tooltip6',
        ToolTip_Relationship_Tooltip7: 'Relationship_Tooltip7',
        ToolTip_Relationship_Tooltip8: 'Relationship_Tooltip8',
        ToolTip_Relationship_Tooltip9: 'Relationship_Tooltip9',
        ToolTip_Relationship_Tooltip10: 'Relationship_Tooltip10',
        ToolTip_Relationship_Tooltip11: 'Relationship_Tooltip11',
        ToolTip_Relationship_Tooltip12: 'Relationship_Tooltip12',
        ToolTip_Relationship_Tooltip13: 'Relationship_Tooltip13',
        ToolTip_Relationship_Tooltip14: 'Relationship_Tooltip14',
        ToolTip_Relationship_Tooltip15: 'Relationship_Tooltip15',
        ToolTip_Relationship_Tooltip16: 'Relationship_Tooltip16',
        HelpMenu_HelpIcon: 'HelpMenu_HelpIcon',
        HelpMenu_FAQ: 'HelpMenu_FAQ',
        HelpMenu_CallSupport: 'HelpMenu_CallSupport',
        InjuryDetailGradual_EditReportType: 'InjuryDetailGradual_EditReportType',
        InjuryDetailPhysical_EditReportType: 'InjuryDetailPhysical_EditReportType',
        InjuryDetailMental_EditReportType: 'InjuryDetailMental_EditReportType',
        Error_reCAPTCHA: 'Error_reCAPTCHA',
        Error_403: 'Error_403',
        Error_Concurrency: 'Error_Concurrency',
        Error_General: 'Error_General',
        Error_MandatoryFieldBlank: 'Error_MandatoryFieldBlank',
        SupportingDoc_UploadDoc: 'SupportingDoc_UploadDoc',
        SupportingDoc_DOA_UploadDoc: 'SupportingDoc_DOA_UploadDoc'
    };

    static getTimeSpentTagKeyByWizardPage(wizardPageName) {
        let tagKey = null;
        switch (wizardPageName) {
            case 'DI_PrivacyPolicy':
                tagKey = 'PrivacyPolicySection_TimeSpent';
                break;
            case 'DI_ReportType':
                tagKey = 'ReportTypeSection_TimeSpent';
                break;
            case 'DI_PersonalDetails':
                tagKey = 'PersonalDetailsSection_TimeSpent';
                break;
            case 'DI_EmployerDetails':
                tagKey = 'EmployerDetailsSection_TimeSpent';
                break;
            case 'DI_WorkerHistory':
                tagKey = 'WorkHistorySection_TimeSpent';
                break;
            case 'DI_InjuryDetails':
                tagKey = 'InjuryDetailsSection_TimeSpent';
                break;
            case 'DI_Healthcare':
                tagKey = 'HealthCareSection_TimeSpent';
                break;
            case 'DI_LostTimeAndEarnings':
                tagKey = 'LostTimeAndEarningsSection_TimeSpent';
                break;
            case 'DI_SupportingDocument':
                tagKey = 'SupportingDocSection_TimeSpent';
                break;
            case 'DI_ReviewAndSubmit':
                tagKey = 'ReviewAndSubmitSection_TimeSpent';
                break;
            case 'DI_ExposureDetails':
                tagKey = 'ExposureDetailsSection_TimeSpent';
                break;
            default: {
                console.log(`Google analytics: *********** Unkowwn page name for ga tag: ${wizardPageName} ************`);
            }
        }
        return tagKey;
    };


    /* Internal helper method to fetch up the respctive Link Data after language Check */
    static getLinkAnalyticsReport(key, lang) {
        let analyticsTag;
        if (lang === 'fr') {
            analyticsTag = AnalyticsLinkService.contentFrench[key];
        } else {
            analyticsTag = AnalyticsLinkService.contentEnglish[key];
        }

        if (!analyticsTag) {
            console.log(`Google analytics: Error: unknown google analytics key - ${key}`);
            throw key;
        }

        return analyticsTag;
    }

    /* Pushing the link Data to the google analytics */
    static sendLinkAnalyticsReport(key, lang) {
    /*    const AnalyticsLinkS = this.getLinkAnalyticsReport(key, lang);
        // tslint:disable-next-line: one-variable-per-declaration
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            link: AnalyticsLinkS,
            event: 'fireTag'
        });
        */
        this.sendAlternativeAnalyticsReport(key, lang);
    }

    /* Appeals and file upload using
       a different method to send tag to the google analytics
    */
    static sendAlternativeAnalyticsReport(key, lang) {
        const AnalyticsLinkS = this.getLinkAnalyticsReport(key, lang);
        console.log("sendAlternativeAnalyticsReport: " + key + ", " + AnalyticsLinkS + ", " + lang);
        // tslint:disable-next-line: one-variable-per-declaration
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: AnalyticsLinkS
        });
    }

    static sendTimeSpentAnalyticsReport(key, lang, timeSpent) {
        const analyticsTag = this.getLinkAnalyticsReport(key, lang);
        // tslint:disable-next-line: one-variable-per-declaration
        const dataLayer = window.dataLayer || [];
        const payload = {
            event: timeSpent,
            event_category: analyticsTag
        };

        dataLayer.push(payload);
    }

    static sendTimeSpentTag(componentTags) {
        const startTime = new Date().getTime();
        const timeSpentTagKey = _.get(componentTags, [
            'componentLevel',
            'timeSpent',
        ]);
        return () => {
            const diff = new Date().getTime() - startTime;
            const timeSpent = Math.round(diff / 1000);

            AnalyticsLinkService.sendTimeSpentAnalyticsReport(
                timeSpentTagKey,
                LocaleService.currentLanguage,
                timeSpent
            );
        };
    }
}

export default AnalyticsLinkService;
