import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import { Link } from 'react-router-dom';
import { Icon } from '@jutro/components';
import tileStyles from './TileComponent.module.scss';

class TileComponent extends Component {
    /**
     * @memberof gw-components-platform-react.TilesComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {string} propTypes.id - id of the component
     * @prop {string} propTypes.title - title of the component
     * @prop {number} propTypes.value - value of the component
     * @prop {string} propTypes.tileIcon - font awesome icon that need to be displayed in component
     * @prop {boolean} propTypes.redirect - enable/disable page redirect
     * @prop {string} propTypes.redirectPath - used when redirect prop is set to true
     * @prop {string} propTypes.tileSize - size of tile - large/medium
     * @prop {function} propTypes.onClick - callback when tile is clicked
     * @prop {boolean} propTypes.active - mark tile as selected
     */

    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.number,
        tileIcon: PropTypes.string,
        redirect: PropTypes.bool.isRequired,
        redirectPath: PropTypes.string,
        tileSize: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        active: PropTypes.bool
    };

    static defaultProps = {
        value: null,
        tileIcon: '',
        redirectPath: '#',
        onClick: null,
        active: false
    };

    tileClicked = (id) => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(id);
        }
    };

    renderContent = () => {
        const {
            title, value, tileIcon
        } = this.props;
        const tileHeaderColor = cx(tileStyles.gwTileColor, 'gwTilesColor');
        return (
            <TranslatorContext.Consumer>
                {(translator) => {
                    return (
                        <React.Fragment>
                            <div className={tileHeaderColor} />
                            <div className={tileStyles.gwTileContent}>
                                { tileIcon
                                    && <Icon icon={tileIcon} /> }
                                {value}
                            </div>
                            <div className={tileStyles.gwTileTitle}>{translator(title)}</div>
                        </React.Fragment>
                    );
                }}
            </TranslatorContext.Consumer>
        );
    };

    renderTiles = () => {
        const {
            id, redirect, redirectPath, active
        } = this.props;
        const tileWrapper = cx(tileStyles.gwTile, 'gwTileMainContent', {
            [tileStyles.gwActive]: active
        });
        const inputProps = { onClick: () => this.tileClicked(id) };
        return (
            <div className={tileWrapper} {...inputProps}>
                {redirect ? (
                    <Link to={redirectPath}>{this.renderContent()}</Link>
                ) : (
                    this.renderContent()
                )}
            </div>
        );
    };

    render() {
        const { tileSize } = this.props;
        const container = cx(tileStyles.gwTiles, {
            [tileStyles.gwTilesLarge]: tileSize === 'large',
            [tileStyles.gwTilesMedium]: tileSize === 'medium'
        });
        return (
            <div className={container}>
                <div className={tileStyles.gwTilesAligner}>{this.renderTiles()}</div>
            </div>
        );
    }
}

export default TileComponent;
