import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldComponent, DateField } from '@jutro/components';
import dateRangeStyles from './DateRangeComponent.module.scss';

class DateRangeComponent extends FieldComponent {
    /**
     * @memberof gw-components-platform-react.DateRangeComponent
     * @prop {Object} propTypes - the props that are passed to this component
     * @prop {boolean} propTypes.fromDate - prop name of the value to be taken from the value obj
     * @prop {boolean} propTypes.toDate - prop name of the value to be taken from the value obj
     * @prop {Object} propTypes.value - value of the base object which
     * contains paths for toDate and fromDate
     */
    static propTypes = {
        ...FieldComponent.propTypes,
        fromDate: PropTypes.string.isRequired,
        toDate: PropTypes.string.isRequired,
        value: PropTypes.shape({}).isRequired
    };

    render() {
        return super.render();
    }

    renderControl() {
        const {
            fromDate, toDate, value, id
        } = this.props;

        const startDate = _.get(value, fromDate);
        const endDate = _.get(value, toDate);

        return (
            <div className={dateRangeStyles.dateRangeContainer} id={id}>
                <DateField
                    id={`${id}_startDateInRange`}
                    className={dateRangeStyles.startDateValue}
                    value={startDate}
                    format="short"
                    dataType={_.isString(startDate) ? 'string' : 'object'}
                    readOnly
                    hideLabel
                    layout="full-width"
                    showOptional={false}
                />
                <span> - </span>
                <DateField
                    id={`${id}_endDateInRange`}
                    className={dateRangeStyles.endDateValue}
                    value={endDate}
                    format="short"
                    dataType={_.isString(endDate) ? 'string' : 'object'}
                    readOnly
                    hideLabel
                    layout="full-width"
                    showOptional={false}
                />
            </div>
        );
    }
}

export default DateRangeComponent;
