import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import usePromise from './usePromise';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withPromiseObject(WrappedComponent) {
    function WithPromiseObject(props) {
        const promiseObj = usePromise();
        return <WrappedComponent {...promiseObj} {...props} />;
    }
    hoistNonReactStatic(WithPromiseObject, WrappedComponent);
    WithPromiseObject.displayName = `withPromiseObject(${getDisplayName(WrappedComponent)})`;
    return WithPromiseObject;
}

export const promiseObjectPropTypes = {
    state: PropTypes.string.isRequired,
    promise: PropTypes.shape({
        then: PropTypes.func,
        catch: PropTypes.func
    }).isRequired,
    resolve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired
};
