import _ from 'lodash';

const EMAIL_REGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/;

export function checkEmailValidity(email) {
    return EMAIL_REGEX.test(email);
}

export function isEmpty(value) {
    return _.isNil(value) || _.isEmpty(value);
}
