import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorLevel } from 'gw-portals-edge-validation-js';
import { withTracking } from 'gw-portals-tracking-react';
import singleErrorStyles from './WizardSingleErrorComponent.module.scss';

function WizardSingleErrorComponent(props) {
    const { issuesList, acknowledgeError } = props;

    const hideWarnings = useCallback(
        (index) => {
            const selectedError = issuesList[index];
            acknowledgeError(selectedError.id);
        },
        [acknowledgeError, issuesList]
    );

    const issuesSection = issuesList.map((issue, index) => {
        const issueType = issue.level === ErrorLevel.LEVEL_ERROR ? 'gwIssuesError' : 'gwIssuesWarning';
        const issueTypeClass = classNames(
            singleErrorStyles.gwIssuesMessage,
            singleErrorStyles[issueType]
        );
        const closeIconClass = classNames(singleErrorStyles.gwIssuesCloseIcon, 'mir', 'mi-close');
        const closeIcon = issue.level === ErrorLevel.LEVEL_WARN ? (
            <div
                role="presentation"
                className={closeIconClass}
                onClick={() => hideWarnings(index)}
            />
        ) : null;

        return (
            <div className={singleErrorStyles.gwIssuesContainer} key={issue.description}>
                <div className={issueTypeClass}>
                    <div className={singleErrorStyles.gwIssuesMessageContainer}>
                        <span>{issue.description}</span>
                    </div>
                    {closeIcon}
                </div>
            </div>
        );
    });

    return <div>{issuesSection}</div>;
}

WizardSingleErrorComponent.propTypes = {
    acknowledgeError: PropTypes.func.isRequired,
    issuesList: PropTypes.shape([])
};
WizardSingleErrorComponent.defaultProps = {
    issuesList: []
};
export default withTracking(WizardSingleErrorComponent);
