import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Button } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './SuccessNotification.metadata.json5';
import styles from './SuccessNotification.module.scss';

const ActionMessageButton = (props) => {
    const translator = useContext(TranslatorContext);
    const {
        id, value, onClick, type, className
    } = props;
    return (
        <Button id={id} className={className} type={type} onClick={onClick}>
            {translator(value)}
        </Button>
    );
};
ActionMessageButton.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired
};
const SuccessNotification = (props) => {
    const {
        onContinueTransaction,
        onWithdrawTransaction,
        notificationContent,
        transactionVisibleActions,
        type
    } = props;
    if (_.isEmpty(notificationContent.infoMessageTitle)) {
        return null;
    }
    const overrides = {
        continueTransaction: {
            visible: _.get(transactionVisibleActions, 'isContinueTransaction') || false
        },
        withdrawTransaction: {
            visible: _.get(transactionVisibleActions, 'isWithdrawTransaction') || false
        },
        successContainerId: {
            className: cx(styles.gwAlert, {
                [styles.gwAlertInfo]: type === 'info',
                [styles.gwAlertWarning]: type === 'warning'
            }),
            columns:
                !_.get(transactionVisibleActions, 'isContinueTransaction')
                && !_.get(transactionVisibleActions, 'isWithdrawTransaction')
                    ? ['1fr']
                    : ['3fr', '2fr']
        },
        infoIcon: {
            icon: type === 'info' ? 'mi-info' : 'mi-warning'
        },
        alertActionsInfo: {
            visible: !(
                !_.get(transactionVisibleActions, 'isContinueTransaction')
                && !_.get(transactionVisibleActions, 'isWithdrawTransaction')
            )
        },
        successInfoHeading: {
            content: _.get(notificationContent, 'infoMessageTitle', '')
        },
        successInfosubHeading: {
            content: _.get(notificationContent, 'infoMessageDescription', '')
        },
        successInfoOptionalHeading: {
            content: _.get(notificationContent, 'optionalInfoMessageDescription', '')
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleWithdrawTransaction: onWithdrawTransaction,
            handleContinueTransaction: onContinueTransaction
        },
        resolveComponentMap: {
            actionmessagecomponent: ActionMessageButton
        }
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={notificationContent}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

SuccessNotification.propTypes = {
    notificationContent: PropTypes.shape({
        infoMessageTitle: PropTypes.string
    }).isRequired,
    onContinueTransaction: PropTypes.func.isRequired,
    onWithdrawTransaction: PropTypes.func.isRequired,
    transactionVisibleActions: PropTypes.shape({
        isContinueTransaction: PropTypes.bool.isRequired,
        isWithdrawTransaction: PropTypes.bool.isRequired
    }).isRequired,
    type: PropTypes.string
};
SuccessNotification.defaultProps = {
    type: 'info'
};
export default SuccessNotification;
