import LoaderComponent from '../loader';

const LoaderComponentComponentMap = {
    LoaderComponent: { component: 'LoaderComponent' }
};

const LoaderComponentComponents = {
    LoaderComponent,
};

export {
    LoaderComponentComponentMap,
    LoaderComponentComponents,
    LoaderComponent
};
