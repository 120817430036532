import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isCapabilityEnabled } from 'gw-portals-config-js';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

const { capabilitiesConfig } = appConfig;

export default function CapabilityRoute({
    component,
    lob,
    capability,
    path,
    exact,
    fallback: Fallback
}) {
    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: capability, lob })) {
        return <Route exact={exact} path={path} component={component} />;
    }
    if (Fallback) {
        return <Fallback />;
    }
    return <Redirect to="/" />;
}

CapabilityRoute.propTypes = {
    component: PropTypes.element.isRequired,
    lob: PropTypes.string,
    capability: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    fallback: PropTypes.element
};

CapabilityRoute.defaultProps = {
    lob: undefined,
    exact: false,
    fallback: undefined
};
