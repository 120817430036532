import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { publish, JUTRO_TOPICS } from '@jutro/events';

export default function withTracking(WrappedComponent) {
    return class extends Component {
        /**
         * @memberof gw-portals-tracking-react.withTracking
         * @prop {Object} propTypes - the props that are passed to this component
         * @prop {string} propTypes.onValueChange - callback when change is made
         * @prop {string} propTypes.onFocusOut - callback when on change of focus
         * @prop {string} propTypes.onClick - callback when clicked on a button
         */

        static propTypes = {
            onValueChange: PropTypes.func,
            onFocusOut: PropTypes.func,
            onClick: PropTypes.func
        };

        static defaultProps = {
            onValueChange: undefined,
            onFocusOut: undefined,
            onClick: undefined
        }

        componentDidMount() {
            publish(JUTRO_TOPICS.COMPONENT_LOADED, {
                ...this.props,
                componentName: WrappedComponent.name
            });
        }

        onClick = (path) => {
            const { onClick } = this.props;
            if (onClick) {
                onClick(path);
            }

            publish(JUTRO_TOPICS.ONCLICK, this.props);
        }

        handleChange = (value, path, evt) => {
            const { onValueChange } = this.props;
            if (onValueChange) {
                onValueChange(value, path, evt);
            }

            publish(JUTRO_TOPICS.VALUE_CHANGED, this.props);
        }

        handleOnBlur = (value, path) => {
            const { onFocusOut } = this.props;
            if (onFocusOut) {
                onFocusOut(value, path);
            }

            publish(JUTRO_TOPICS.FIELD_BLUR, this.props);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onValueChange={this.handleChange}
                    onFocusOut={this.handleOnBlur}
                    onClick={this.onClick}
                />
            );
        }
    };
}
