import React from 'react';
import { FieldComponent } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';

import LinkComponent from '../LinkComponent/LinkComponent';
import styles from './FieldLinkComponent.module.scss';

class FieldLinkComponent extends FieldComponent {
    static contextType = TranslatorContext;

    static propTypes = {
        ...FieldComponent.propTypes,
        ...LinkComponent.propTypes
    };

    renderControl() {
        const { value } = this.props;

        return (
            <LinkComponent className={styles.fieldLinkColor} {...this.props}>
                {value}
            </LinkComponent>
        );
    }

    render() {
        return super.render();
    }
}

export default FieldLinkComponent;
