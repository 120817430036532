// Adding support to HTML Elements which are not supported by JUTRO

import React from 'react';
import PropTypes from 'prop-types';
import { setComponentMapOverrides } from '@jutro/uiconfig';

const CustomHTMLElement = (element) => {
    const Component = element;

    const HTMLElement = (props) => {
        const { children } = props;
        return (
            <Component {...props}>
                { children }
            </Component>
        );
    };

    HTMLElement.propTypes = {
        children: PropTypes.node
    };

    HTMLElement.defaultProps = {
        children: null
    };

    return HTMLElement;
};

const addHTMLElements = new Set([
    'ul',
    'li',
    'form',
    'ol'
]);

const componentMap = {};
const components = {};

addHTMLElements.forEach((element) => {
    // adding to componentMap
    componentMap[element] = { component: element };

    // adding to components
    components[element] = CustomHTMLElement(element);
});

setComponentMapOverrides(componentMap, components);
