import _ from 'lodash';
import { getFlattenedUiPropsContent } from 'gw-portals-viewmodel-react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';

/**
 * Allows you to use the jutro read viewmodel value as a
 * fallback when overriding values in the override props
 * @param {GlobalMetadata} uiProps - the metadata content
 * @param {Object|ViewModel} model - the viewmodel
 * @param {string} id
 * @param {string} path
 * @returns {Object} the processed node
 */
function readValue(uiProps, model, id, path) {
    const uiPropsContent = getFlattenedUiPropsContent(uiProps);

    const componentUiProps = uiPropsContent.find((item) => {
        const regex = new RegExp(`^${item.id}\\d*$`);
        return regex.test(id);
    }) || {};
    const { componentProps = {} } = componentUiProps;
    const { passViewModel } = componentProps;

    const node = _.get(model, path);

    if (passViewModel) {
        return node;
    }

    if (ViewModelUtil.isViewModelNode(node)) {
        return node.value;
    }

    return node;
}

/**
 * Allows you to use the jutro read viewmodel value as a
 * fallback when overriding values in the override props
 * @param {GlobalMetadata} uiProps - the metadata content
 * @param {Object|ViewModel} model - the viewmodel
 * @param {string} id
 * @param {string} path
 * @param {Object} overrides
 * @returns {Object} the processed node
 */
export default function readViewModelValue(uiProps, model, id, path, overrides = {}) {
    const overrideEntry = Object.entries(overrides).find(
        ([entryId, entryOverrides]) => {
            // eslint-disable-next-line no-prototype-builtins
            return entryId === id && entryOverrides.hasOwnProperty('value');
        }
    );
    if (overrideEntry) {
        return overrideEntry[1].value;
    }
    return readValue(uiProps, model, id, path);
}
