import { defineMessages } from 'react-intl';

export default defineMessages({
    firstName: {
        id: 'auth.components.SignUp.First Name:',
        defaultMessage: 'First Name:'
    },
    lastName: {
        id: 'auth.components.SignUp.Last Name:',
        defaultMessage: 'Last Name:'
    },
    emailAddress: {
        id: 'auth.components.SignUp.Email:',
        defaultMessage: 'Email:'
    },
    password: {
        id: 'auth.components.SignUp.Password:',
        defaultMessage: 'Password:'
    },
    confirmPassword: {
        id: 'auth.components.SignUp.Confirm Password:',
        defaultMessage: 'Confirm Password:'
    },
    signUp: {
        id: 'auth.components.SignUp.Sign Up',
        defaultMessage: 'Sign Up'
    },
    fieldEmpty: {
        id: 'auth.components.SignUp.All fields are required to sign up.',
        defaultMessage: 'All fields are required to sign up.'
    },
    invalidEmail: {
        id: 'auth.components.SignUp.Please enter a valid email address.',
        defaultMessage: 'Please enter a valid email address.'
    },
    passwordsNotMatching: {
        id: 'auth.components.SignUp.Fields password and confirmation do not match.',
        defaultMessage: 'Fields password and confirmation do not match.'
    },
    emailAlreadyExists: {
        id: 'auth.components.SignUp.Email already exists',
        defaultMessage: 'Email already exists'
    },
    emailAlreadyExistsDescription: {
        id: 'auth.components.SignUp.An account with the email address you entered already exists.',
        defaultMessage: 'An account with the email address you entered already exists.'
    },
    invalidAttempt: {
        id: 'auth.components.SignUp.Invalid Attempt',
        defaultMessage: 'Invalid Attempt'
    },
    unableToCreateUserAccount: {
        id: 'auth.components.SignUp.Unable to create user account',
        defaultMessage: 'Unable to create user account'
    },
    unableToLoginAutomatically: {
        id: 'auth.components.SignUp.Unable to login automatically',
        defaultMessage: 'Unable to login automatically'
    },
    unableToLoginAutomaticallyDescription: {
        id: 'auth.components.SignUp.Account successfully created, however, not able to login automatically. Please return to Sign In and enter your email and password.',
        defaultMessage: 'Account successfully created, however, not able to login automatically. Please return to Sign In and enter your email and password.'
    },
    doYouHaveAccount: {
        id: 'auth.components.SignUp.Already have an account?',
        defaultMessage: 'Already have an account?'
    },
    signIn: {
        id: 'auth.components.SignUp.Sign In',
        defaultMessage: 'Sign In'
    },
});
