import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'gw-portals-error-react';

function YoutubeComponent(props) {
    const { config, id } = props;

    const {
        type, id: listID, autoplay, controls
    } = config;

    return (
        <iframe
            id={id}
            title="YouTube video player"
            allowFullScreen="1"
            width="100%"
            height="auto"
            src={`https://www.youtube.com/embed/Kb-hIXRqtD8?playlist=tgbNymZ7vqY?controls=${controls}?autoplay=${autoplay}?listType=${type}_uploads?list=${listID}`}
        />
    );
}

YoutubeComponent.propTypes = {
    id: PropTypes.string,
    config: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string,
        autoplay: PropTypes.bool,
        controls: PropTypes.bool
    }).isRequired
};

YoutubeComponent.defaultProps = {
    id: undefined
};

export default withErrorBoundary({ resetError: false })(YoutubeComponent);
