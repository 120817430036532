import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import SessionTimeoutModal from '../common/session-timeout-modal/session-timeout-modal';
import SessionTimeout from '../common/session-timeout-modal/SessionTimeout';
import Configuration from '../key-configuration/configuration.json5';

const { sessionConfig, authentication: authConfig } = config;

function getConfig() {
    if (_.isNil(authConfig)) {
        return undefined;
    }
    const serverConfig = _.get(authConfig, `servers.${authConfig.authServer}`);
    return { ...authConfig, ...serverConfig };
}
function SessionTimeoutService() {
    const oAuthConfig = getConfig();
    return SessionTimeout({
        oAuthConfig,
        logoutWarningCallback: SessionTimeoutModal,
        inactivityIntervalMins: Configuration.sessionConfig.inactivityIntervalMins,
        logoutConfirmationIntervalMins: Configuration.sessionConfig.logoutConfirmationIntervalMins
    });
}
export default SessionTimeoutService;
