import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { withValidation, validationPropTypes } from 'gw-portals-validation-react';
import { ViewModelUtil } from 'gw-portals-viewmodel-js';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import SingleClauseComponentVM from '../Clauses/SingleClauseComponentVM';
import QuoteTableAccordionCardIterableComponent from '../QuoteTableAccordionCardIterableComponent/QuoteTableAccordionCardIterableComponent';
import UnderwritingIssues from '../UnderwritingIssues/UnderwritingIssues';
import metadata from './QuoteClauseTable.metadata.json5';
import styles from './QuoteClauseTable.module.scss';
import './QuoteClauseTable.messages';

const BLOCKS_QUOTES = ['BlocksQuote', 'BlocksQuoteRelease'];
const BLOCKS_BIND = 'BlocksBind';
const NON_BLOCKING = 'NonBlocking';

function getChangedDataPath(changedPath, fullChangedPath, pathToChangeTo) {
    const localisedVMPath = changedPath.replace(fullChangedPath, pathToChangeTo);
    return ViewModelUtil.getNonVMPath(localisedVMPath);
}

function getChangedColumnData(changedPathOfModel, columnData) {
    // onBlur is called from Jutro with a object instead of a path
    const path = _.isString(changedPathOfModel) ? changedPathOfModel : changedPathOfModel.model;
    return columnData.find(({ lob }) => _.includes(path, lob.path));
}

function getChangedClause(path, columnData) {
    const changedObjectPath = ClausesUtil.getObjectPathFromChangedPath(path);
    const changedColumn = getChangedColumnData(path, columnData);
    const changedPath = getChangedDataPath(changedObjectPath, changedColumn.lob.path, 'lob.data');
    return _.get(changedColumn, changedPath);
}

function getCoverageIndex(path) {
    const hasSubCoverages = ClausesUtil.hasSubCoverages(path);

    if (hasSubCoverages) {
        const parentCoveragePath = ClausesUtil.getParentCoveragePath(path);
        if (parentCoveragePath) {
            // Regex to get index of the child element, eg. '2'  for ...children[2]
            const pathIdentifier = parentCoveragePath.split('.').slice(-1);
            const [, index] = /\[(\d+)\][^[]*$/.exec(pathIdentifier) || [];
            return index ? `__${index}` : '';
        }
    }

    return '';
}

function selectMetadata(breakpoint) {
    const contentArray = metadata.componentContent.content;
    let metadataToRender = '';

    if (breakpoint === 'phone') {
        metadataToRender = contentArray.filter((item) => item.id !== 'desktopTable');
    } else {
        metadataToRender = contentArray.filter(
            (item) => item.id !== 'phoneTable' && item.id !== 'mobileAccordionHeader'
        );
    }

    const filteredMetdata = {
        ...metadata,
        componentContent: {
            content: metadataToRender
        }
    };

    return filteredMetdata;
}

function getSelectedCoverages(data) {
    // only return the selected base coverages names
    const coveragesObject = _.get(data, 'lob.data.coverages');
    const coverageNames = Object.keys(coveragesObject).filter((name) => {
        return _.includes(name.toLowerCase(), 'coverages');
    });
    const baseCoverageName = _.first(coverageNames);
    const coverages = _.get(coveragesObject, baseCoverageName);
    return coverages.filter((cov) => cov.selected);
}

const PAYMENT_TYPES = {
    monthly: 'monthly',
    annually: 'annually'
};

const moneyPropsTypes = PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.number
});

class QuoteClauseTable extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        columnData: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                code: PropTypes.string,
                quote: PropTypes.shape({
                    path: PropTypes.string,
                    premium: PropTypes.shape({
                        monthlyPremium: moneyPropsTypes,
                        total: moneyPropsTypes
                    })
                })
            })
        ).isRequired,
        tableData: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string,
                data: PropTypes.arrayOf(
                    PropTypes.shape({
                        publicID: PropTypes.string
                    })
                ),
                tableContent: PropTypes.arrayOf(
                    PropTypes.shape({
                        path: PropTypes.string,
                        clauses: PropTypes.arrayOf(PropTypes.shape({})),
                        code: PropTypes.string
                    })
                )
            })
        ).isRequired,
        underwritingIssues: PropTypes.arrayOf({}),
        filterUWIssuesInCustomOffering: PropTypes.bool,
        quoteID: PropTypes.string.isRequired,
        onBuyNow: PropTypes.func,
        onRecalculate: PropTypes.func,
        onResetQuote: PropTypes.func,
        onChangeSubmissionAndSync: PropTypes.func,
        onChangeSubmission: PropTypes.func,
        onSyncCoverages: PropTypes.func,
        onStaleQuoteBranchCode: PropTypes.func,
        ...validationPropTypes
    };

    static defaultProps = {
        onBuyNow: undefined,
        onRecalculate: undefined,
        onResetQuote: undefined,
        onChangeSubmissionAndSync: undefined,
        onChangeSubmission: undefined,
        onSyncCoverages: undefined,
        onStaleQuoteBranchCode: undefined,
        underwritingIssues: [],
        filterUWIssuesInCustomOffering: true
    };

    state = {
        formData: {},
        loadingClause: undefined,
        staleQuoteBranchCodes: this.getStaleCode(),
        resetBranchCodes: [],
        priceDifference: 0,
        previousColumnData: []
    };

    componentDidMount() {
        const { columnData } = this.props;
        const resetBranchCodes = columnData.map((item) => item.code);
        this.setState({ resetBranchCodes, previousColumnData: _.cloneDeep(columnData) });
    }

    getTableColumnContent(columnData, tableInfo) {
        return columnData.map((data, index) => {
            return {
                id: `quoteTableColumn${index}`,
                type: 'element',
                component: 'tablecolumn',
                componentProps: {
                    cellClassName: 'gwTableCell',
                    onCell: this.renderCellContent,
                    data: tableInfo.tableContent.find((content) => content.code === data.code)
                }
            };
        });
    }

    getStaleCode() {
        const { onStaleQuoteBranchCode } = this.props;
        let val = [];
        if (onStaleQuoteBranchCode) {
            if (onStaleQuoteBranchCode()) {
                const staleCodes = onStaleQuoteBranchCode();
                if (_.isArray(staleCodes)) {
                    val = staleCodes;
                } else {
                    val.push(staleCodes);
                }
            }
        }
        return val;
    }

    getChangedOfferingPathsAndSetClauseToLoading(path) {
        const { columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;

        const changedColumn = getChangedColumnData(path, columnData);
        const changedClause = getChangedClause(path, columnData);
        const coverageIndex = getCoverageIndex(path);
        const lobPath = changedColumn.lob.path;
        const quotePath = changedColumn.quote.path;
        const updatedBranchCodes = resetBranchCodes.filter((item) => item !== changedColumn.code);

        staleQuoteBranchCodes.push(changedColumn.code);

        this.setState({
            loadingClause: {
                clauseID: changedClause.coveragePublicID
                    || `${changedClause.publicID}${coverageIndex}`,
                quoteCode: changedColumn.code
            },
            staleQuoteBranchCodes,
            resetBranchCodes: updatedBranchCodes
        });

        return [lobPath, quotePath];
    }

    removeStaleQuote = (path) => {
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const selectedColumn = _.get(this.props, path);

        const newStateQuoteArray = staleQuoteBranchCodes.filter(
            (item) => item !== selectedColumn.code
        );
        const newResetBranchArray = resetBranchCodes.filter((item) => item !== selectedColumn.code);
        this.setState({
            staleQuoteBranchCodes: newStateQuoteArray,
            resetBranchCodes: newResetBranchArray
        });
    };

    getClickedChangedPaths = (evt) => {
        const path = evt.currentTarget.attributes.path.value;
        const selectedColumn = _.get(this.props, path);
        const lobPath = selectedColumn.lob.path;
        const quotePath = selectedColumn.quote.path;

        return [path, lobPath, quotePath];
    };

    renderMobileQuoteHeader = (accordionData, index, isFormValid) => (isOpen) => {
        const {
            formData, staleQuoteBranchCodes, resetBranchCodes, priceDifference
        } = this.state;
        const scheduleStaleCode = this.getStaleCode();
        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }
        const isAnnually = formData.paymentToggle === PAYMENT_TYPES.annually;
        const isQuoteStale = _.includes(staleQuoteBranchCodes, accordionData.code);
        const isResetBranch = _.includes(resetBranchCodes, accordionData.code);
        const shouldShowPriceDifference = _.includes(priceDifference.columnPath, `[${index}]`)
            && !isQuoteStale
            && priceDifference.difference !== 0;
        const price = isAnnually
            ? accordionData.quote.data.premium.total
            : accordionData.quote.data.premium.monthlyPremium;

        const dataForComponent = {
            price,
            quoteName: accordionData.name,
            selectedCoverages: getSelectedCoverages(accordionData)
        };

        // Due to having a new render content, there may be duplicate ID's
        const overrides = {
            monthlyAmountText: {
                visible: formData.paymentToggle === PAYMENT_TYPES.monthly
            },
            annuallyAmountText: {
                visible: formData.paymentToggle === PAYMENT_TYPES.annually
            },
            buyNowButton: {
                path: `columnData[${index}]`,
                visible: !isQuoteStale,
                onClick: (event) => {
                    event.stopPropagation();
                    this.buyNow(accordionData.quote.path, accordionData.lob.path);
                }
            },
            recalculateButton: {
                path: `columnData[${index}]`,
                visible: isQuoteStale,
                disabled: !isFormValid,
                onClick: (event) => {
                    event.stopPropagation();
                    this.recalculate(
                        `columnData[${index}]`,
                        accordionData.quote.path,
                        accordionData.lob.path
                    );
                }
            },
            resetCoverages: {
                path: `columnData[${index}]`,
                visible: !isResetBranch || isQuoteStale || shouldShowPriceDifference,
                onClick: (event) => {
                    event.stopPropagation();
                    this.resetQuote(
                        `columnData[${index}]`,
                        accordionData.quote.path,
                        accordionData.lob.path
                    );
                }
            },
            selectedCoverages: {
                visible: !isOpen
            },
            moreInfoContainer: {
                visible: !isOpen
            },
            accordionChevron: {
                isOpen
            },
            accordionStickyHeader: {
                className: classNames(styles.accordionStickyHeader, {
                    [styles.mobileStickyHeader]: isOpen
                })
            }
        };

        const resolvers = {
            resolveClassNameMap: styles
        };

        const metadataToRender = metadata.contentForMobileView.content;
        return (
            <ViewModelForm
                uiProps={metadataToRender}
                model={dataForComponent}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    };

    renderCellContent = (clauseCellUniqueID, index, props) => {
        const { loadingClause } = this.state;
        const { onChangeSubmission, onValidate } = this.props;
        const { path, clauses = [], code } = props.data;
        const clauseIndex = clauses.findIndex(
            (clause) => clauseCellUniqueID.coverageUniqueID.split('__')[0] === clause.publicID
        );
        const clause = clauses[clauseIndex];
        const isQuoteLoading = _.get(loadingClause, 'quoteCode') === code && _.isUndefined(loadingClause.clauseID);
        const isClauseLoading = _.get(loadingClause, 'clauseID') === clauseCellUniqueID.coverageUniqueID
            && _.get(loadingClause, 'quoteCode') === code;

        if (clauseIndex !== -1) {
            return (
                <SingleClauseComponentVM
                    value={clause}
                    path={`${path}.children[${clauseIndex}]`}
                    onChangeClause={onChangeSubmission}
                    onSyncCoverages={this.syncCoverages}
                    onChangeSubmissionAndSync={this.changeSubmissionAndSync}
                    loadingClause={isClauseLoading || isQuoteLoading}
                    idPrefex={code}
                    labelTop
                    labelPosition="top"
                    onValidate={onValidate}
                    showAmount={false}
                />
            );
        }
        return null;
    };

    writeValue = (value, path) => {
        const { formData } = this.state;
        _.set(formData, path, value);
        this.setState({ formData });
    };

    syncCoverages = (value, path) => {
        const { onSyncCoverages } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onSyncCoverages) {
            onSyncCoverages(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    };

    changeSubmissionAndSync = (value, path) => {
        const { onChangeSubmissionAndSync } = this.props;

        const [lobPath, quotePath] = this.getChangedOfferingPathsAndSetClauseToLoading(path);

        if (onChangeSubmissionAndSync) {
            onChangeSubmissionAndSync(value, path, lobPath, quotePath).then(() => {
                this.setState({ loadingClause: undefined });
            });
        }
    };

    buyNow = (quotePath, lobPath) => {
        const { onBuyNow } = this.props;

        if (onBuyNow) {
            onBuyNow(lobPath, quotePath);
        }
    };

    recalculate = (columnPath, quotePath, lobPath) => {
        const { onRecalculate, columnData } = this.props;
        const { previousColumnData } = this.state;
        const changedColumnData = getChangedColumnData(lobPath, previousColumnData);
        let monthlyPayment = 0;
        if (changedColumnData.quote.data.status === 'Quoted') {
            monthlyPayment = changedColumnData.quote.data.premium.monthlyPremium.amount;
        }

        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });

        if (onRecalculate) {
            onRecalculate(lobPath, quotePath).then((response) => {
                const newMonthlyPayment = response.quote.premium.monthlyPremium.amount;
                this.removeStaleQuote(columnPath);
                this.setState({
                    priceDifference: {
                        columnPath: columnPath,
                        difference: newMonthlyPayment - monthlyPayment
                    },
                    previousColumnData: _.cloneDeep(columnData),
                    loadingClause: undefined
                });
            });
        }
    };

    resetQuote = (columnPath, quotePath, lobPath) => {
        const { onResetQuote, columnData } = this.props;
        const { staleQuoteBranchCodes, resetBranchCodes } = this.state;
        const changedColumnData = getChangedColumnData(lobPath, columnData);
        this.setState({
            loadingClause: {
                quoteCode: changedColumnData.code
            }
        });
        if (onResetQuote) {
            onResetQuote(lobPath, quotePath).then(() => {
                staleQuoteBranchCodes.push(changedColumnData.code);
                resetBranchCodes.push(changedColumnData.code);
                this.setState({
                    loadingClause: undefined,
                    staleQuoteBranchCodes,
                    resetBranchCodes
                });
            });
        }
    };

    generateDesktopHeaderOverrides() {
        const { columnData, isComponentValid, underwritingIssues } = this.props;
        const {
            formData, staleQuoteBranchCodes, resetBranchCodes, priceDifference
        } = this.state;

        const scheduleStaleCode = this.getStaleCode();
        if (_.isEmpty(staleQuoteBranchCodes) && !_.isEmpty(scheduleStaleCode)) {
            this.setState({ staleQuoteBranchCodes: scheduleStaleCode });
        }
        const overrides = columnData.map(({ code, quote, lob }, index) => {
            const columnPath = `columnData[${index}]`;
            const isQuoteStale = _.includes(staleQuoteBranchCodes, code);
            const isResetBranch = _.includes(resetBranchCodes, code);
            const clearAmount = { amount: undefined };

            let monthlyValue = clearAmount;
            let annuallyValue = clearAmount;
            let isQuoteBlocked = false;
            if (quote.data) {
                const { data } = quote;
                const monthlyPremium = Object.assign({}, clearAmount, data.premium.monthlyPremium);
                const total = Object.assign({}, clearAmount, data.premium.total);
                monthlyValue = Object.assign({}, clearAmount, monthlyPremium);
                annuallyValue = Object.assign({}, clearAmount, total);
                isQuoteBlocked = underwritingIssues.some(
                    (uwIssue) => uwIssue.offering === data.branchName
                        && uwIssue.currentBlockingPoint !== NON_BLOCKING
                );
            }

            const monthlyHeader = isQuoteStale
                ? { [`monthlyAmount${index}`]: { value: monthlyValue } }
                : {};
            const annuallyHeader = isQuoteStale
                ? { [`annuallyAmount${index}`]: { value: annuallyValue } }
                : {};
            const shouldShowPriceDifference = _.includes(priceDifference.columnPath, `[${index}]`)
                && !isQuoteStale
                && priceDifference.difference !== 0;

            return {
                [`quoteAnnuallyAmount${index}`]: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.annually
                },
                [`quoteMonthlyAmount${index}`]: {
                    visible: formData.paymentToggle === PAYMENT_TYPES.monthly
                },
                [`priceDifference${index}`]: {
                    visible: shouldShowPriceDifference,
                    value: priceDifference.difference
                },
                [`buyNowButton${index}`]: {
                    visible: !isQuoteStale && quote.data.status !== 'Draft',
                    onClick: () => this.buyNow(quote.path, lob.path),
                    disabled: isQuoteBlocked
                },
                [`recalculateButton${index}`]: {
                    visible: isQuoteStale || quote.data.status === 'Draft',
                    disabled: !isComponentValid,
                    onClick: () => this.recalculate(columnPath, quote.path, lob.path)
                },
                [`resetCoverages${index}`]: {
                    visible: !isResetBranch,
                    onClick: () => this.resetQuote(columnPath, quote.path, lob.path)
                },
                [`monthlyAmountText${index}`]: { visible: !isQuoteStale },
                [`annuallyAmountText${index}`]: { visible: !isQuoteStale },
                [`warningIcon${index}`]: {
                    visible: isQuoteBlocked
                },
                ...monthlyHeader,
                ...annuallyHeader
            };
        });

        return Object.assign({}, ...overrides);
    }

    generatePhoneHeaderOverrides() {
        const { tableData, columnData, isComponentValid } = this.props;

        const overrides = columnData.map((data, index) => {
            return {
                [`tableContainer${index}`]: {
                    header: this.renderMobileQuoteHeader(data, 0, isComponentValid)
                },
                [`quoteTableAccordionCardIterableComponentContainer${index}`]: {
                    tableData: tableData,
                    renderCellContent: this.renderCellContent,
                    onValueChange: this.writeValue
                }
            };
        });

        return Object.assign({}, ...overrides);
    }

    generateDesktopTableOverrides() {
        const { tableData, columnData } = this.props;

        const overrides = tableData.map((tableInfo, index) => ({
            [`quoteTable${index}`]: {
                data: tableInfo.data,
                title: tableInfo.header,
                content: this.getTableColumnContent(columnData, tableInfo)
            }
        }));

        return Object.assign({}, ...overrides);
    }

    generateDesktopOverrides() {
        return {
            ...this.generateDesktopHeaderOverrides(),
            ...this.generateDesktopTableOverrides()
        };
    }

    generatePhoneOverrides() {
        return {
            ...this.generatePhoneHeaderOverrides()
        };
    }

    findBlockingPoint(type) {
        const { underwritingIssues } = this.props;
        return underwritingIssues.some((offeringIssue) => {
            if (type === BLOCKS_BIND) {
                return offeringIssue.currentBlockingPoint === BLOCKS_BIND;
            }
            return _.includes(BLOCKS_QUOTES, offeringIssue.currentBlockingPoint);
        });
    }

    generateOverrides(breakpoint) {
        const { underwritingIssues, quoteID, filterUWIssuesInCustomOffering } = this.props;
        const breakpointOverrides = breakpoint === 'phone'
            ? this.generatePhoneOverrides()
            : this.generateDesktopOverrides();
        return {
            '@field': {
                // apply to all fields
                onValueChange: this.writeValue
            },
            underWritingIssues: {
                underwritingIssues: underwritingIssues,
                blockQuote: this.findBlockingPoint(),
                quoteID: quoteID,
                filterUWIssuesInCustomOffering
            },
            paymentToggle: {
                visible: !this.findBlockingPoint()
            },
            tableContainer: {
                visible: !this.findBlockingPoint()
            },
            tableHeader: {
                visible: !this.findBlockingPoint()
            },
            ...breakpointOverrides
        };
    }

    renderClauseTable = (breakpoint) => {
        const { setComponentValidation } = this.props;
        const { formData } = this.state;

        const dataForComponent = {
            ...formData,
            ..._.pick(this.props, ['columnData', 'tableData'])
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveComponentMap: {
                chevron: Chevron,
                quotetableaccordioncarditerablecomponent: QuoteTableAccordionCardIterableComponent,
                underwritingissues: UnderwritingIssues
            }
        };
        const generatedMetadata = selectMetadata(breakpoint);
        return (
            <ViewModelForm
                uiProps={generatedMetadata.componentContent}
                model={dataForComponent}
                overrideProps={this.generateOverrides(breakpoint)}
                onValueChange={this.writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        );
    };

    render() {
        return (
            <BreakpointTrackerContext.Consumer>
                {this.renderClauseTable}
            </BreakpointTrackerContext.Consumer>
        );
    }
}

export default withValidation(QuoteClauseTable);
