/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { getProxiedServiceUrl } from 'gw-portals-url-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonRPCService } from 'gw-portals-transport-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DocumentUploadService } from 'gw-portals-document-js';
// eslint-disable-next-line no-warning-comments
// US-OSBC-215 <exposed the methods to fetch account-claim releated informations for specific user>
class ClaimService {
    static claimFeatures = {
        claimUploadDocumentFeatureEnabled: true,
        claimSecureMessagingFeatureEnabled: true,
        claimDocumentHistoryFeatureEnabled: true,
        claimBenefitPaymentsFeatureEnabled: true,
        claimHealthCareBenefitsFeatureEnabled: true,
        claimNonHealthCareBenefitsFeatureEnabled: true,
        claimSummariesFeatureEnabled: true,
        claimEnrollmentFeatureEnabled: true,
        claimAppealsFeatureEnabled: true
    };

    static getAuthHeader(caller = '') {
        let accessToken = document.getElementsByTagName('html')[0].getAttribute('authheader');
        console.log('Oauth token for request: ', caller, accessToken);
        accessToken = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined;
        return accessToken;
    }

    static getClaimSummaries(query, offset, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimSummaries_WSIB', [query, offset], this.getAuthHeader());
    }


    static unLockDirectDeposit(pinNumber, claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'unLockDirectDeposit', [pinNumber, claimNumber], this.getAuthHeader());
    }

    static savePinFailure(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'savePinFailureEvent', [claimNumber], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getClaimDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getWorkerClaimDetail_WSIB', [params], this.getAuthHeader());
    }

    /*
     * @param {string} claimNumber
     * @param {string} employeeLastName
     * @param {object} additionalHeaders
     * @returns claim Details
     * fetch claim details
     */
    static getSearchClaimDetails(param, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getSearchClaimDetail_WSIB', [param], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getPaymentDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimBenefitPayments_WSIB', [params], this.getAuthHeader());
    }

    /*
    * @param {string} params
    * @param {object} authheader
    * @returns generateToken for document upload
    */

    static claimsDocUploadToken(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claimDocs'),
            'generateUploadToken',
            params, additionalHeaders);
    }

    /*
    * @param {file} file
    * @param {object} documentMetadata
    * @param {object} additionalHeaders
    * @returns submit document
    */
    static uploadDocument(file, documentMetadata, additionalHeaders = {}) {
        return DocumentUploadService.send(getProxiedServiceUrl('claimDocumentUpload'),
            file, documentMetadata, this.getAuthHeader());
    }

    // ospcchange to delete submitted document item for DI (Dynamic intake) project
    static deleteDocument(parameter, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claimDocs'),
            'removeDocument_WSIB',
            parameter, additionalHeaders);
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getHealthCareDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimHealthCareBenefits_WSIB', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getNonHealthCareDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimNonHealthCareBenefits_WSIB', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getAppealsDetails(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimAppeals_WSIB', [params], this.getAuthHeader());
    }

    /*
     * @param {string} claim number
     * @returns claim Details
     * fetch direct deposit details
     */
    static getDirectDepositInfo(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'getClaimDirectDeposit', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * verify direct deposit dob
     */
    static directDepositVerifyDob(claimNumber, month, year, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'verifyDob', [claimNumber, month, year], this.getAuthHeader());
    }

    static directDepositFailureToServer(claimNumber, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'createHistoryForAuthFailure', [claimNumber], this.getAuthHeader());
    }

    /* sample request
    {
        "id":"a81a3f5e-65e9-4353-a03f-edf3c5679655_13",
        "jsonrpc":"2.0",
        "method":"getBankInfo",
        "params":
        [
            "0010",
            "00502"
        ]
    }

    //sample response
    {
        "id":"a81a3f5e-65e9-4353-a03f-edf3c5679655_13",
        "result":"CANADIAN IMPERIAL BANK OF COMMERCE\nSSNTS & MMSSQ MHNHUNT DSNDQS\n2 BLOOR ST. W.\n2 BLOOR ST. W.\nTORONTO, ON, M5V 3J1",
        "jsonrpc":"2.0"
    }
*/
    static directDepositVerifyBankInfo(transitNumber, institutionNumber, additionalHeaders = {}) {
        const fullInstNumber = `0${institutionNumber}`;
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'getBankInfo', [fullInstNumber, transitNumber], this.getAuthHeader());
    }

    /* sample request for add bank info
    {
        "id":"a81a3f5e-65e9-4353-a03f-edf3c5679655_8",
        "jsonrpc":"2.0",
        "method":"updateDirectDepositBankInfo",
        "params":
        [{
            "bankAccountNumber":"1234567",
            "wsibbankNumber":"0010",
            "wsibbranchNumber":"00502",
            "claimNumber":"31430007"
        }]
    }

    sample request for edit bank info
    {
        "id":"a81a3f5e-65e9-4353-a03f-edf3c5679655_14",
        "jsonrpc":"2.0",
        "method":"updateDirectDepositBankInfo",
        "params":
        [{
            "bankAccountNumber":"12345678",
            "wsibbankNumber":"0010",
            "wsibbranchNumber":"00502",
            "publicID":"cc:1012094",
            "claimNumber":"31430007"
        }]
    }

    sample response for add bank info of success
    {
        "id":"a81a3f5e-65e9-4353-a03f-edf3c5679655_8",
        "result":"true",
        "jsonrpc":"2.0"
    }
*/
    static updateDirectDepositInfo(bankinformation, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'updateDirectDepositBankInfo', [bankinformation], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch direct deposit account status
     */
    static getDirectDepositAccountStatus(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
            'getUserAccountStatus', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch claim details
     */
    static getMessageList(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customerMessage'),
            'getMessagesForCustomer', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * save message
     */
    static saveMessage(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customerMessage'),
            'saveMessage', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * message count
     */
    static messageCount(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customerMessage'),
            'getNewMessageCount', [params], this.getAuthHeader());
    }

    /*
     * @param {string} params
     * get Message Thread
     */
    static getMessageThread(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('customerMessage'),
            'getMessageThread', params, this.getAuthHeader());
    }

    /*
     * @param {string} params
     * get Message Thread
     */
    static getTombstoneData(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getTombstoneDetail_WSIB', [params], this.getAuthHeader());
    }

    static getAccountFilterData(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getAccountFilterList_WSIB', params, this.getAuthHeader());
    }

    static getActiveClaims(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getActiveClaimSummaries_WSIB', params, this.getAuthHeader());
    }

    static getFilterClaims(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimAdvanceSearchSummary_WSIB', params, this.getAuthHeader());
    }

    static getArchiveClaims(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getArchiveClaimSummaries_WSIB', params, this.getAuthHeader());
    }

    static getFilterClaimsClaimNo(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('claim'),
            'getClaimSummariesByClaimNumber_WSIB', params, this.getAuthHeader());
    }

    static addWorkerEnrollmentRecord(params, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('enrollment'),
            'addWorkerEnrollmentRecord_WSIB', [params], this.getAuthHeader());
    }
}

class MockClaimService extends ClaimService {
    static getClaimSummaries(query, offset, additionalHeaders = {}) {
        // return JsonRPCService.send(getProxiedServiceUrl('claim'),
        //     'getClaimSummaries_WSIB', [query, offset], this.getAuthHeader());
        const okData = {
            id: 'd9b4985b-ffd0-4651-aa4b-a4b51067cc80_1',
            result: {
                items: [
                    {
                        publicID: 'cc:4001',
                        claimNumber: '30000020',
                        lossDate: '2022-02-17T04:44:47Z',
                        claimState: 'open',
                        entitlementStatus_WSIB: 'Allowed',
                        employer_WSIB: 'IBM'
                    },
                    {
                        publicID: 'cc:101',
                        claimNumber: '30000003',
                        lossDate: '2017-12-30T03:19:17Z',
                        claimState: 'open',
                        entitlementStatus_WSIB: 'Pending',
                        employer_WSIB: 'MINAS CORP LTD CANADA'
                    },
                    {
                        publicID: 'cc:201',
                        claimNumber: '30000008',
                        lossDate: '2017-12-29T18:37:17Z',
                        claimState: 'open',
                        entitlementStatus_WSIB: 'Allowed',
                        employer_WSIB: 'MINAS CORP LTD CANADA'
                    },
                    {
                        publicID: 'cc:301',
                        claimNumber: '30000015',
                        lossDate: '2017-12-23T15:46:34Z',
                        claimState: 'open',
                        entitlementStatus_WSIB: 'Allowed',
                        employer_WSIB: 'MINAS CORP LTD CANADA'
                    },
                    {
                        publicID: 'cc:401',
                        claimNumber: '30000016',
                        lossDate: '2017-12-19T21:01:55Z',
                        claimState: 'open',
                        entitlementStatus_WSIB: 'Allowed',
                        employer_WSIB: 'MINAS CORP LTD CANADA'
                    }
                ],
                maxNumberOfResults: 5,
                claimArchived_WSIB: false,
                claimFeatures_WSIB: {
                    formFieldContentDecodedEncodedKeywords: 'or:ro,OR:RO,Or:Ro,AND:NDA,And:Nda,and:nda',
                    formFieldContentSpecialCharReplaceWithUniCodeKeywords: "':α;,:β",
                    claimUploadDocumentFeatureEnabled: true,
                    claimSecureMessagingFeatureEnabled: true,
                    claimDocumentHistoryFeatureEnabled: true,
                    claimBenefitPaymentsFeatureEnabled: true,
                    claimHealthCareBenefitsFeatureEnabled: true,
                    claimNonHealthCareBenefitsFeatureEnabled: true,
                    claimSummariesFeatureEnabled: true,
                    claimEnrollmentFeatureEnabled: true,
                    claimAppealsFeatureEnabled: true
                },
                directDepositDisplayFlag_WSIB: true
            },
            jsonrpc: '2.0'
        };
        const emptyData = {
            id: 'edac3044-af8f-46d2-aa44-7e86af0edd48_1',
            result: {
                items: [],
                maxNumberOfResults: 0,
                claimArchived_WSIB: false,
                claimFeatures_WSIB: {
                    formFieldContentDecodedEncodedKeywords: 'or:ro,OR:RO,Or:Ro,AND:NDA,And:Nda,and:nda,not:otn,NOT:OTN,Not:otN',
                    formFieldContentSpecialCharReplaceWithUniCodeKeywords: "':α;,:β",
                    claimUploadDocumentFeatureEnabled: true,
                    claimSecureMessagingFeatureEnabled: true,
                    claimDocumentHistoryFeatureEnabled: true,
                    claimBenefitPaymentsFeatureEnabled: true,
                    claimHealthCareBenefitsFeatureEnabled: true,
                    claimNonHealthCareBenefitsFeatureEnabled: true,
                    claimSummariesFeatureEnabled: true,
                    claimEnrollmentFeatureEnabled: true,
                    claimAppealsFeatureEnabled: true
                },
                directDepositDisplayFlag_WSIB: false
            },
            jsonrpc: '2.0'
        };
        let data = okData;
        if (sessionStorage.getItem('mockclaims') === 'empty') {
            data = emptyData;
        }
        return Promise.resolve(data.result);
    }

    /*
     * @param {string} claim number
     * @returns claim Details
     * fetch direct deposit details
     */
    static getDirectDepositInfo(params, additionalHeaders = {}) {
        // return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
        //    'getClaimDirectDeposit', [params], this.getAuthHeader());

        const bankInfo = {
            id: 'df272aff-bb95-425b-bc3c-d428c0b7040d_1',
            result: {
                publicID: 'cc:1',
                wsibbranchNumber: '00011',
                bankAccountNumber: '******353',
                wsibbankNumber: '0001',
                bankAddress: 'MONTREAL (SUCC. PRINCIPALE)\n119 RUE SAINT-JACQUES\nMONTREAL, QC, H2Y 1L6',
                bankName: 'BANK OF MONTREAL'
            },
            jsonrpc: '2.0'
        };

        const bankInfoNoData = {
            id: 'c962a6f9-9956-4751-9ad5-fc7023445b26_4',
            result: {},
            jsonrpc: '2.0'
        };

        const bankInfoHtmlError = {
            appErrorCode: 101,
            baseError: undefined,
            errorCode: 'GW_ERROR',
            gwInfo: {
                method: 'getClaimDirectDeposit',
                params: ['31670002'],
                serviceEndPoint: 'https://sit.ospc.wsib.ca/claim/directdeposit',
                isTimeout: false,
                isUnauthenticated: false
            }
        };

        if (sessionStorage.getItem('mockbankinfo') === 'error') {
            return Promise.reject(bankInfoHtmlError);
        // eslint-disable-next-line no-else-return
        } else {
            let ret = bankInfoNoData;
            if (sessionStorage.getItem('mockbankinfo') === 'true') {
                ret = bankInfo;
            }

            return Promise.resolve(ret.result);
        }
    }

    /*
     * @param {string} params
     * @returns claim Details
     * fetch direct deposit account status
     */
    static getDirectDepositAccountStatus(params, additionalHeaders = {}) {
    //   return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
    //       'getUserAccountStatus', [params], this.getAuthHeader());
        const accountStatusUnlocked = {
            id: '8958076d-f08c-4db4-9bbc-b649dbbb8b5b_2',
            result: 'true',
            jsonrpc: '2.0'
        };
        const accountStatusLocked = {
            id: '8958076d-f08c-4db4-9bbc-b649dbbb8b5b_2',
            result: 'false',
            jsonrpc: '2.0'
        };

        let ret = accountStatusUnlocked;
        if (sessionStorage.getItem('mocklocked') === 'true') {
            ret = accountStatusLocked;
        }
        return Promise.resolve(ret.result);
    }

    // test cases:
    // input 1900/01 to turn failure.
    // otherwise, return success
    static directDepositVerifyDob(claimNumber, month, year, additionalHeaders = {}) {
        // return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
        //    'verifyDob', [claimNumber, month, year], this.getAuthHeader());
        let ret = { result: 'true' };

        if (year === '1900' && month === '01') {
            ret = { result: 'false' };
        }
        return Promise.resolve(ret.result);
    }

    // this mock method does not handle server response, as the response does not
    // matter for client. Instead, the mock method set the session storage item to
    // the account is locked by server
    static directDepositFailureToServer(claimNumber, additionalHeaders = {}) {
        // return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
        //    'createHistoryForAuthFailure', [claimNumber], this.getAuthHeader());
        sessionStorage.setItem('mocklocked', 'true');
        return Promise.resolve({});
        // return Promise.reject(new Error('reqeust failed'));
    }

    // all input are valid, except transit 000 and institution 00000
    static directDepositVerifyBankInfo(transitNumber, institutionNumber, additionalHeaders = {}) {
        let ret = {
            id: 'cbd4d7db-6974-4f2c-b16d-61d269379f0a_1',
            result: 'CANADIAN IMPERIAL BANK OF COMMERCE\nYONGE & BLOOR\n2 BLOOR ST. W.\nTORONTO, ON, M4W 3E2',
            jsonrpc: '2.0'
        };

        if (institutionNumber === '000' && transitNumber === '00000') {
            ret = {
                id: '6845232f-5543-480c-8b27-a3f31ddefed3_1',
                result: {},
                jsonrpc: '2.0'
            };
        }
        return Promise.resolve(ret.result);
    }

    static updateDirectDepositInfo(bankinformation, additionalHeaders = {}) {
        // return JsonRPCService.send(getProxiedServiceUrl('directDeposit'),
        //    'updateDirectDepositBankInfo', [bankinformation], this.getAuthHeader());

        // sample response
        // {"id":"7026aeec-ca5a-4917-81a2-80c7ce9c71d8_12","result":"true","jsonrpc":"2.0"}
        let ret = { result: 'true' };

        if (bankinformation.wsibbankNumber === '0000' && bankinformation.wsibbranchNumber === '00000') {
            ret = { result: 'false' };
        } else {
            sessionStorage.setItem('mockbankinfo', 'true');
        }
        return Promise.resolve(ret.result);
    }
}

// to enable mock data, in sessionStorage, set mockdata to 'true'.
// hardcode below mockData variable to true to avoid the above step and always use mock data
// in case the service is unavailable

// const mockData = 'true'; // force to test with mockdata
const mockData = sessionStorage.getItem('mockdata');

export default mockData === 'true' ? MockClaimService : ClaimService;
